.receipts-mobile {
    display: flex;
    flex-direction: column;

    gap: 20px
}

.receipt-mobile-content {
    gap: 20px;
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    background: 
        linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    border: 1px solid #f5f5f730;
}

.receipt-mobile-header {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--white);
} 

.receipt-mobile-header-concept {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.receipt-mobile-header-detail {
    display: flex;
    flex-direction: row;
    gap: 5px
}

.receipt-mobile-text {
    color: #FFFFFF;
    font-family: 'Poppins-Medium';
}

.receipt-mobile-text_ {
    color: #FFFFFF;
}

.receipt-mobile-body {
    width: 100%;
    display: flex;
    gap: 1rem;

    display: flex;
    flex-direction:row;
    justify-content: space-between;
    flex-wrap:wrap;
}

.receipt-mobile-body-concept {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px
}

.receipt-mobile-body-detail {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.receipt-mobile-body-detail-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    max-width: 130px;
}

.receipt-mobile-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.receipt-mobile-footer .custom-button-icon {
    padding: 5px 40px;
    background: var(--gray-4);
}

.receipt-mobile-footer .custom-button-icon .custom-button-icon-text {
    color: #FFFFFF;
} 

.receipt-mobile-a {
    display: flex;
    flex-direction: row;
    gap: 5px;
    text-decoration: none;
    color: white;
    align-items: center;
    background: #d4d4d44d;
    padding: 7px 25px;
    border-radius: 20px;
}

.receipt-mobile-a div {
    display: flex;
}

@media (min-width: 435px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 160px;
    }
}

@media (min-width: 466px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 180px;
    }
}

@media (min-width: 500px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 90px;
        flex-direction: column;
    }
}

@media (min-width: 540px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 100px;
        flex-direction: column;
    }
}

@media (min-width: 580px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 110px;
        flex-direction: column;
    }
}

@media (min-width: 640px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 125px;
        flex-direction: row;
    }
}

@media (min-width: 700px) {  
    .receipt-mobile-body-detail-row { 
        max-width: 135px;
    }
}

@media (min-width: 760px) {  
    .receipt-mobile { 
        display: none;
    }
}