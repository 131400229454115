.aboutSection {
    display: flex;
    flex-direction: column;
    background-position: top;
    background-size: cover;
    /* clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%); */
    margin-top: -10rem;
}
.aboutSection-first {
    display: flex;
    padding: 0rem 0rem 2.5rem 0rem;
    /* flex-direction: row; */
    /* gap: 2rem; */
    /* padding: 1rem 2.5rem; */
}

.aboutSection-first .aboutSection-detail .card-gradient {
    margin-top: 14rem;
    width: 300px;
}

.aboutSection-img {
    width: 100%;
    min-width: 328px;
    max-width: 554px;
}

.aboutSection-first-img {
    display: none;
}

.aboutSection-first-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 11rem;
    justify-content: center;
    align-items: center;
}

.aboutSection-first-text {
    color: var(--white);
    font-size: 1.625rem;
    margin-top: -3rem;
}

.aboutSection-card-gradient {
    min-width: 308px;
    max-width: 415px;
}

.aboutSection-first-detail .card-gradient .card-gradient-content {
    min-width: 308px;
    padding: 20px;
    background-image: 
        linear-gradient(to right, rgba(255 183 13) 13%, rgba(238 91 16) 60%),         
        linear-gradient(to right, rgb(255 183 13) 70%, rgb(238 91 16) 91%);
    background-size: 100% 2px;
} 

.aboutSection-first-detail-animation .animation-video {
    height: 730px;
    min-height: 730px;
}

.aboutSection-first-detail .card-gradient .card-gradient-content::before { 
    border: 2px solid #FFC502;
    border-right: none;
}

.aboutSection-first-detail .card-gradient .card-gradient-content::after { 
    border: 2px solid #F26B12;
    border-left: none;
}

.aboutSection-first-detail .card-gradient .card-gradient-content .card {
    width: 100%;
    padding: 0px;
    background: none;
    border: 0px solid #F5F5F7;
    backdrop-filter: none;
}

.aboutSection-first .aboutSection-detail .card-gradient {
    margin-top: 14rem;
    width: 100%;
}

.aboutSection-text {
    color: var(--white);
    padding: 1rem 1.5rem;
    font-size: 2.125rem;
}

.aboutSection-first-detail-animation {
    padding-left: 1rem;
}

.aboutSection-second-detail {
    display: flex;
    padding: 0rem 1rem;
}

.aboutSection-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutSection-img-web {
    display: none;
}

.aboutSection-text-mobile {
    display: flex;
}
.aboutSection-text-web {
    display: none;
}

@media (min-width: 400px) {  
    .aboutSection-first .aboutSection-detail .card-gradient {
        width: 300px;
    }
}

@media (min-width: 470px) {  
    .aboutSection-first-detail-animation .animation-video {
        height: 740px;
    }
}

@media (min-width: 570px) {  
    .aboutSection-first-detail-animation {
        padding-left: 4rem;
    }
}

@media (min-width: 615px) {  
    .aboutSection-first-detail-animation {
        padding-left: 7rem;
    }
}

@media (min-width: 666px) {  
    .aboutSection-first-detail-animation {
        padding-left: 9rem;
    }
    .aboutSection-img {
        max-width: 700px;
    }
}

@media (min-width: 740px) {  
    .aboutSection-second-detail {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 768px) {  
    .aboutSection-first {
        gap: 2rem;
        /* background: #010408; */
        background-image: none;
        clip-path: none;
        padding: 1rem 2.5rem 6rem 2.5rem;

        justify-content: center;
        align-items: center;
        align-self: center;
    }

    .aboutSection-first-detail {
        align-items: center;
        padding-top: 24rem;
        padding-bottom: 7rem;
    }

    .aboutSection-first-img {
        display: flex;
        /* height: 535px;
        width: 340px; */
        height: 535px;
        width: 301px;
    }
    .aboutSection-body {
        display: flex;
        flex-direction: row;
    }
    .aboutSection-img-web {
        display: flex;
        width: 100%;
        position: absolute;
        max-width: 477px;
        margin-top: -9rem;
    }

    .aboutSection-img {
        display: none;
    }

    .aboutSection-first-text {
        margin-top: 0px;
    }

    .aboutSection-first .aboutSection-detail .card-gradient {
        margin-top: 0px;
        width: 100%;
        min-width: 320px;
        max-width: 350px;
    }
    .aboutSection-first-detail .card-gradient .card-gradient-content .card {
        width: 100%;
    }
    .aboutSection-second-detail {
        gap: 10px;
        padding: 0px;
        padding-left: 24rem;
    }
    .aboutSection-text-mobile {
        display: none;
    }
    .aboutSection-text-web {
        display: flex;
        padding: 0rem;
        font-size: 2rem;
    }
    .aboutSection-first-detail-animation {
        padding-left: 0rem;
    }  
    .card-subTitle {
        font-size: 1rem;
    } 
    /* .aboutSection {
        clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
    } */
}

@media (min-width: 869px) {  
    .aboutSection {
        margin-top: -14rem;
    }
}

@media (min-width: 900px) {  
    .aboutSection-first-img {
        height: 610px;
        width: 344px;
        border-radius: 24px;
    }
}


@media (min-width: 976px) {  
    .aboutSection {
        /* clip-path: polygon(0 0, 100% 23%, 100% 100%, 0 100%); */
        margin-top: -16rem;
    }
}

@media (min-width: 1000px) {  
    .aboutSection-first {
        padding: 1rem 2.5rem 8rem 2.5rem;
    }
}

@media (min-width: 1100px) {  
    .aboutSection {
        /* clip-path: polygon(0 0, 100% 23%, 100% 100%, 0 100%); */
        margin-top: -18rem;
    }
}

@media (min-width: 1200px) {  
    .aboutSection {
        /* clip-path: polygon(0 0, 100% 27%, 100% 100%, 0 100%); */
        margin-top: -19rem;
    }
}

@media (min-width: 1680px) {  
    .aboutSection {
        /* clip-path: polygon(0 0, 100% 27%, 100% 100%, 0 100%); */
        margin-top: -24rem;
    }
}

@media (min-width: 1940px) {  
    .aboutSection {
        /* clip-path: polygon(0 0, 100% 27%, 100% 100%, 0 100%); */
        margin-top: -26rem;
    }
}

@media (min-width: 2110px) {  
    .aboutSection {
        /* clip-path: polygon(0 0, 100% 27%, 100% 100%, 0 100%); */
        margin-top: -28rem;
    }
    .aboutSection-first {
        padding: 12rem 2.5rem 8rem 2.5rem;
    }
}