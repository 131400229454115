.deletedSubscription-header {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.deletedSubscription-text {
    color: var(--white);
}

.deletedSubscription .deletedSubscription-header .button-container {
    display: none;
    padding: 10px 20px;
}

.deletedSubscription .button-container {
    display: flex;
}

.deletedSubscription-changePassword {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.deletedSubscription .deletedSubscription-changePassword-btn {
    display: flex;
}


@media (min-width: 600px) { 
    .deletedSubscription-header {
        flex-direction: row;
        align-items: center;
        gap: 10px
    }
    .deletedSubscription .deletedSubscription-header .button-container  {
        display: flex;
        padding: 10px 20px;
        font-size: 0.75rem;
    }
    /* .deletedSubscription-text {
        font-size: 0.875rem;
    } */
    .deletedSubscription .button-container {
        display: none;
    }
    .deletedSubscription .deletedSubscription-changePassword-btn {
        display: flex;
    }
}

@media (min-width: 801px) { 
    .deletedSubscription .deletedSubscription-header .button-container  {
        font-size: 0.875rem;
    }
    /* .deletedSubscription-text {
        font-size: 1rem;
    } */
    .deletedSubscription .button-container {
        width: 200px;
        
    }
}

@media (min-width: 960px) { 
    .deletedSubscription .deletedSubscription-header .button-container  {
        font-size: 0.875rem;
    }
    .deletedSubscription .button-container {
        width: 241px;
    }
    /* .deletedSubscription-text {
        font-size: 1rem;
    } */
}