.profile-container {
    width: 100%;
    height: 100vh;
    /* height: 100%; */
    /* margin-top: 60px; */
    padding: 5rem 3.75rem 1rem 3.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    /* background-image: url('../../../public/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TA.png'); */
    background-color: #000000;
    background-attachment: initial;
    background-position: top;
    background-repeat: no-repeat;
}

.profile-content {
    max-width: 1024px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;

    overflow-y: scroll;
}

.profile-container-loader {
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    width: 100%;
}

.profile-content::-webkit-scrollbar {
    display: none;
} 

.profile-left {
    max-width: 350px;
    width: 100%;
}

.profile-left .cardUser-container .card--gradient-text {
    border-radius: 20px;
    background-size: 100% 1px;
    background-image: 
        linear-gradient(to right, rgb(84 63 212 / 38%) 0%, rgb(255 108 3 / 8%) 100%), 
        linear-gradient(to right, rgb(84 63 212 / 50%) 0%, rgb(255 117 16 / 8%) 100%);
}

.profile-left .cardUser-container .card--gradient-text::before {
    width: 16px;
    border: 1px solid #5b47c366;
    border-right: none;
}

.profile-left .cardUser-container .card--gradient-text::after {
    width: 16px;
    border: 1px solid #76543e2b;
    border-left: none;
}

.profile-right {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media (max-width: 1440px) {
    .profile-left {
        max-width: 350px;
        width: 100%;
    }
}

@media (max-width: 1170px) {
    .profile-left {
       max-width: 280px;
    }  
}

@media (max-width: 890px) {
    .profile-container {
        padding: 70px 20px 20px 20px;
    }
}
@media (max-width: 768px) {
    .profile-left {
        display: none;
    }
    .profile-container {
        padding: 55px 10px 20px 10px;
    }
}
/* 
@media (max-width: 1000px) {
    .profile-container .cardUser-container {
        display: flex;
        max-width: 315px;
        width: 100%;
    }
} */
/* @media (max-width: 900px) {
    .profile-container .cardUser-container {
        display: flex;
        max-width: 275px;
        width: 100%;
    }
} */
/* 
@media (max-width: 885px) {
    .profile-container .cardUser-container {
        display: flex;
        max-width: 310px;
        width: 100%;
    }
} */

