.carousel-month-gradient {
    max-width: 425px;
    width: 100%;
    height: 36px;
    /* background-image: url(../../../public/images/png/selectBackground.png); */
    background-size: cover;
}

.carousel-month-gradient .card-gradient-content {
    padding: 10px;
    background-color: transparent;
    background-image: 
        linear-gradient(to right, rgb(224 225 227) 0%, rgb(255 255 255 / 0%) 60%), 
        linear-gradient(to right, rgb(244 244 244) 0%, rgb(255 255 255 / 0%) 60%);
    background-size: 100% 2px;
    &:before,
		&:after {
			border: 2px solid #ebebed;
            border-right: none;
		}
		
		&:after {
			border: 2px solid #7e85924f;
            border-left: none;
		}
}

.carousel-month-gradient .card-gradient-content::after,
.carousel-month-gradient .card-gradient-content::before {
    border: 2px solid #ebebed;
    border-right: none;
}

.carousel-month-gradient .card-gradient-content::after {
    border: 2px solid #7e859203;
    border-left: none;
}

.carousel-month {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* padding: 10px; */  
    border-radius: 20px;
    /* border: 2px solid #FFF; */
}

.carousel-list {
    color: var(--white);
}