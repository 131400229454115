.creditNumber {
    width: 100%;
    max-width: 300px;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    gap: 5px
}

.creditNumber-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px
}

.creditNumber-data {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px

}

.creditNumber-textColor {
    width: 100%;
    color: white;
    /* font-family: "Poppins-Medium";
    font-size: 0.8rem; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.creditNumber-icon {
    width: 46.6px;
    height: 21px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 4px;
}

.creditNumber-icon img {
    height: 15px;
}

.creditNumber-number {
    max-width: 115px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--white);

}

.creditNumber-text {
    color: white;
    font-family: "Poppins-Medium";
    font-size: 0.8rem;
    align-items: center;
    display: flex;
}