.receipts-content {
    display: none;
}

.receipts-header {
    width: 100%;

    display: flex;
    flex-direction: row;
}

.receipts-text {
    color: #FFF;
}

.receipts-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.receipts-content .card-background {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
}

.receipts-content .card-gradient .card-gradient-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-size: 100% 1px;
    background-image: 
        linear-gradient(to right, rgb(84 63 212 / 58%) 0%, rgba(255 117 16 / 19%) 74%), 
        linear-gradient(to right, rgb(84 63 212 / 58%) 0%, rgb(255 117 16 / 15%) 60%);
}

.receipts-content .card-gradient .card-gradient-content::before {
    border: 1px solid #5b47c366;
    border-right: none;
}   

.receipts-content .card-gradient .card-gradient-content::after {
    border: 1px solid #76543e30;
    border-left: none;
}

.receipts-content .card-gradient .card-gradient-content .receipts-body .tableBody-container  {
    background: linear-gradient(135deg, rgb(255 255 255 / 15%) 0%, rgba(255, 255, 255, 0.00) 100%);
    border-radius: 12px;
    backdrop-filter: blur(0px);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.08) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.04) inset;
}

.receipts-content .card-gradient .card-gradient-content .receipts-body 
.tableBody-container .card-gradient-content {
    padding: 10px 15px;
    border-radius: 12px !important;
    border: 1px solid 1px solid rgb(255 255 255 / 12%) !important;
    background-image: none;
}

.receipts-content .card-gradient .card-gradient-content .receipts-body 
.tableBody-container .card-gradient-content::before {
    border: 0px solid #ffffff0d !important;
    border-right: none !important;
    border-radius: 12px 0 0 12px;
}

.receipts-content .card-gradient .card-gradient-content .receipts-body 
.tableBody-container .card-gradient-content::after {
    border: 0px solid #ffffff0d !important;
    border-right: none !important;
    border-radius: 0 24px 24px 0;
}

.receipts-nothing {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (min-width: 760px) { 
    .receipts-content {
        display: flex;
    }
}