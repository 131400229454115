.register {
    /* display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 5rem 1.5rem; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    padding: 10px;

    background: #222633;
    overflow: auto;
    position: fixed;
    z-index: 9000;

    background-attachment: initial;
    background-position: top;

    backdrop-filter: blur(5px);
    background-color: rgb(0 0 0 / 75%);
    background-image: none;
}

.register .card-background {
    max-width: 450px;
    min-height: -moz-fit-content;
    min-height: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 15px;
    align-items: flex-start;
    border-radius: 24px;
    border: 1px solid #F5F5F7;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgb(255 255 255 / 28%) 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.register-container {
    width: 100%;

    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: space-between;
}


.register .card-background .register-container .register-body .registerSocial .card-background,
.register .card-background .register-container .register-body .registerGmail .card-background,
.register .card-background .register-container .register-body .registerCode .card-background,
.register .card-background .register-container .register-body .registerFull .card-background {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 24px;
    border: 1px solid #F5F5F7;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.register .card-background .register-container .register-body .registerPay .subscriptionConcept .card-background,
.register .card-background .register-container .register-body .registerPay .creditCard .card-background {
    display: flex;
    width: 100%;
    padding: 30px;
    flex-direction: column;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); 
    border-radius: 0px;
    border: 0px;
}

.register .card-background .register-container .register-body .registerPay .creditCard .card-background {
    border-radius: 24px;
}

.register-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.register-body {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: calc(120vh - 340px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;  
    overflow-x: none;
    overflow-y: auto;
}

.register-header-subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.register-text {
    color: var(--white);
}

.register-text-orange {
    color: var(--orange);
}

.register-buttons {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.register-buttons .custom-button-icon {
    height: 45px;
}

.register-gmail {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 20px;
}

.register-gmail .button-container,
.register-code .button-container {
    background: var(--orange);
    color: var(--white);

    padding: 10px 20px;
    border-radius: 50px;
}

.register-loader {
    width: 500px;
    display: flex;
    justify-content: center;
}

@media (min-width: 768px) { 
    .register { 
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgb(0 0 0 / 75%);
        background-image: none
    }

    .register-loader {
        height: 500px;
        width: 500px;
        display: flex;
        justify-content: center;
    }

    .register .card-background {
        width: 100%;
        /* max-height: 700px; */
        max-width: 450px;
        min-height: fit-content;
        height: fit-content;
    
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
        /* gap: 30px; */

        border-radius: 24px;
        border: 1px solid #F5F5F7;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgb(255 255 255 / 28%) 100%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    .register .card-background .register-container .register-body .registerSocial,
    .register .card-background .register-container .register-body .registerGmail,
    .register .card-background .register-container .register-body .registerCode,
    .register .card-background .register-container .register-body .registerFull {
        /* max-width: 360px; */
    }

    .register .card-background .register-container .register-body .registerSocial .card-background,
    .register .card-background .register-container .register-body .registerGmail .card-background,
    .register .card-background .register-container .register-body .registerCode .card-background,
    .register .card-background .register-container .register-body .registerFull .card-background {
        display: flex;
        max-width: 100%;
        min-height: fit-content;
        height: fit-content;

        background: none;
        backdrop-filter: none;
        border: 0px;
        border-radius: 0px;
        padding: 0px;
    }

    .register-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .register,
    .register-container {
        width: 100%;
        /* height: 700px; */
        display: flex;
        justify-content: center;
        align-items: center;
     
        
    }

    .register-container .goBack-back {
        width: 100%;
    }

    .register-header {
        width: 100%;
    }

    .register-body {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: calc(110vh - 340px);
        
        display: flex;
        overflow-x: none;
        overflow-y: auto;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
    }

    .register-body::-webkit-scrollbar {
        display: none;
    }

    .register-container--close {
        display: flex;
    }

    .register-text {
        display: flex;
        font-family: 'FuturaPT-Bold';
        font-size: 1.625rem;
    }

    .register-text-subtitle,
    .register-text-orange {
        display: flex;
        font-family: 'Poppins-Medium';
        font-size: 0.75rem;
    }

    .register .card-background .register-container .register-body .registerPay .subscriptionConcept .card-background,
    .register .card-background .register-container .register-body .registerPay .creditCard .card-background {
        display: flex;
        width: 100%;
        padding: 30px;
        flex-direction: column;
        background: linear-gradient(165deg, rgb(218 218 218 / 0%) 0%, rgb(255 255 255 / 0%) 100%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px); 
    }
}