.card {
    display: flex;
    width: 455px;
    padding: 30px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    border-radius: 24px;
    border: 1px solid #F5F5F7;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(10px);
}

.card-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.card-subTitle {
    color: var(--white);
    text-align: justify;
}


@media (max-width: 768px) { 
    .card {
        width: 286px;
    }
}