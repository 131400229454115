.carouselHoroscopeMin-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.carouselHoroscopeMin-list {
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-snap-align: center center;
    scroll-behavior: smooth;
}

.carouselHoroscopeMin .card-gradient-content {
    height: 72px;
    padding: 10px 15px;
    background-size: 100% 2px;
    background-image: 
        linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
        linear-gradient(to right, rgb(255, 199, 0) 26%, rgb(241 104 18 / 100%) 100%)
}

.carouselHoroscopeMin .card-gradient-content::before,
.carouselHoroscopeMin .card-gradient-content::after {
    border: 2px solid #FFC700;
    border-right: none;
    width: 20px;
}

.carouselHoroscopeMin .card-gradient-content::after {
    border: 2px solid #F16812 !important;
    border-left: none !important;
}

@media (min-width: 600px) {
    .carouselHoroscopeMin .card-gradient-content {
        background-image: 
        linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
        linear-gradient(to right, rgb(255, 199, 0) 40%, rgb(241 104 18 / 100%) 100%)
    }
 }

@media (min-width: 770px) {
   #carouselHoroscopeMin-leftIcon, #carouselHoroscopeMin-rightIcon {
    display: none !important;
    cursor: pointer;
   }
}

