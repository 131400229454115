.awards-header {
    width: 100%;

    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
    border-bottom: 1px solid #FFF;
}
.awards-text {
    color: #FFF;
}

.awards-notFount {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
}

.awards-container .card-gradient  .card-gradient-content {
    padding: 30px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    background-size: 100% 1px;
    background-image: 
        linear-gradient(to right, rgb(84 63 212 / 58%) 0%, rgba(255 117 16 / 19%) 74%), 
        linear-gradient(to right, rgb(84 63 212 / 58%) 0%, rgb(255 117 16 / 15%) 60%);
}

.awards-container .card-gradient  .card-gradient-content::before {
    border: 1px solid #5b47c366;
    border-right: none;
}   

.awards-container .card-gradient .card-gradient-content::after {
    border: 1px solid #76543e30;
    border-left: none;
}