.carousel-lecture .card-gradient-content  {
    background-size: 100% 2px;
    padding: 20px 0px 20px 20px;
    background-image: 
        linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
        linear-gradient(to right, rgb(255, 199, 0) 26%, rgb(241 104 18 / 100%) 100%)
}

.carousel-lecture .card-gradient-content .carousel-container .carousel-content {
    padding-right: 20px;
}

.carousel-lecture .card-gradient-content::before,
.carousel-lecture .card-gradient-content::after {
    border: 2px solid #FFC700;
    border-right: none;
}

.carousel-lecture .card-gradient-content::after {
    border: 2px solid #F16812;
    border-left: none;
}

.carousel-container {
    width: 100%;
    min-height: 240px;
    height: 100%;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-right: 0.14rem;

    /* border-radius: 24px;
    border: 1px solid rgba(84, 63, 212, 0.30);

    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);

    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px); */
}

.carousel-title {
    width: 100%;
    min-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.carousel-title img {
    width: 20px;
    height: 20px;
}

.carousel-text {
    color: var(--white);
}

 .carousel-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    /* gap: 20px; */
    gap: 1rem;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    /* justify-content: center; */
}

.carousel-content::-webkit-scrollbar {
    display: none;
    width: 100%;
    height: 100%;
}

.carousel-column {
    display: flex;
    flex-direction: column;
}

.carousel-contenedor {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.carousel-separator {
    background-color: var(--white);
    width: 100%;
}

@media (min-width: 600px) {
    .carousel-lecture .card-gradient-content  {
        background-size: 100% 2px;
        padding: 20px 0px 20px 20px;
        background-image: 
            linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
            linear-gradient(to right, rgb(255, 199, 0) 83%, rgb(241 104 18 / 100%) 100%)
    }
}