.modalCheckout-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.modalcheckout-mesagge {
    color: var(--white);
}

.no-scroll {
    overflow: hidden;
}