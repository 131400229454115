.registerFull {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.registerFull .button-container {
    background: var(--orange);
    color: var(--white);

    padding: 10px 20px;
    border-radius: 50px;
}