.countryList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.countryList-text {
    color: var(--white);
    font-family: 'Poppins-Medium';
}

.countryList-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    gap: 4px;
    /* background-color: var(--white); */
    border-radius: 16px;
}
.countryList-input {
    height: 46px;
    display: flex;
    padding: 14px 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    border-radius: 16px;
    background: rgba(0, 0, 0, 0.20);

    color: #FFF;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.48px;
    opacity: 0.6;
    border: 0px;
}

.countryList--list {
    width: 100%;
    max-height: 18rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    overflow-y: scroll;
    border-radius: 16px;
    background: var(--white);
}

.countryList--list::-webkit-scrollbar {
    display: none;
}

.countryList-select {
    width: 100%;
    padding: 14px 24px 14px 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    border-radius: 16px;  
    background: rgba(0, 0, 0, 0.20);
    opacity: 0.6;
}

.countryList-country--flag {
    width: 30px;
}

.countryList-select--name {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
    color: #FFF;
}