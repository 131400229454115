.home {
    width: 100%;
    max-width: 1440px;
    height: 100%;
    min-height: 100%;
	display: flex;
	flex-direction: row;
    /* background-image: url('../../../public/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TA.png'); */
    background-color: #000000;
    background-attachment: initial;
    background-position: top;
}

.home-content {
    width: 100%;
	height: 100vh;
    max-height: 100%;
	display: flex;
	flex-direction: row;
    gap: 20px;
    padding-top: 3rem;
}

.home-div {
    text-align: center;
    color: white;
    font-family: sans-serif;
    font-size: 36px;
    padding: 10px;
    
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow: hidden;
}
	
.home-left {
    width: 100%;
    max-width: 370px;
    height: calc(100vh - 56px);
    display: none;
    gap: 30px;
    height: 100%;
    flex:1;
}

.home-center {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    min-width: 345px;
    padding: 10px 10px 0px 10px;
}

.home-center-body {
    height: 100%;
    display: flex;
    flex-direction: column; 
    gap: 10px;
    }

.home-center-body .carousel-lecture {
    height: 100%;
}

.home-center-body .carousel-lecture .card-gradient-content {
    height: 100%;
    padding: 20px 0px 0px 20px;
}

.home-center-body .carousel-lecture .card-gradient-content 
.carousel-container .carousel-content .carouselCard-container {
    width: 100%;
    text-align: left;
}
.home-center-body .carousel-lecture .card-gradient-content 
.carousel-container .carousel-content {
    height: 100vh;
    padding-bottom: 15rem;
    /* min-height: calc(100vh - 285px);
    max-height: calc(100vh - 285px); */
}
.home-right {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
   
    flex: 1;
  
}
.home-right-info {
    display: flex;
    flex-direction: row;
    text-align: start;
    color: var(--white);
}

.home-content-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px
}

.home-center-body .carouselHoroscopeMin .card-gradient-content 
.carouselHoroscopeMin-container {
    gap: 10px
}

.home-center-body .carouselHoroscopeMin .card-gradient-content 
.carouselHoroscopeMin-container #carouselHoroscopeMin-leftIcon,
.home-center-body .carouselHoroscopeMin .card-gradient-content 
.carouselHoroscopeMin-container #carouselHoroscopeMin-rightIcon {
    display: flex !important;
}

.home-center-body .carouselHoroscopeMin .card-gradient-content 
.carouselHoroscopeMin-container .carouselHoroscopeMin-list 
.itemHoroscopeMin-container {
    min-width: fit-content;
}

.home .modal-main .modal-container .modal-text div form .creditCard .card-background {
    border: 0px solid rgba(84, 63, 212, 0.30);
    border-radius: 5px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: none;
}

.home .modal-main .modal-container .modal-text div form .creditCard .card-background .creditCard-body {
    height: 44vh;
}

@media (min-width: 690px) { 
    .home-center {
        display: flex;
        height: 100%;
        flex: 1;
        flex-direction: column;
        gap: 20px;
        min-width: 345px;
        max-width: 430px;
    }
    .home-right {
        display: flex;
        height: 100%;
        flex: 1;
        /* max-width: 465px;
        min-width: 345px; */
    } 
    .home-content-tabs {
        display: none;
    }
    .home-center-body .carousel-lecture .card-gradient-content 
    .carousel-container .carousel-content {
        height: 100%;
        min-height: calc(100vh - 233px);
        max-height: calc(100vh - 233px);
    }
    .home-center-body .carousel-lecture .card-gradient-content  {
        padding: 20px 0px 0px 20px;
        background-image: 
            linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
            linear-gradient(to right, rgb(255, 199, 0) 27%, rgb(241 104 18 / 100%) 100%);
    }
    .home-right-info {
        display: none;
    }

}

@media (min-width: 769px) { 
    .home-content {
        display: flex;
        justify-content: center;
        padding-top: 3.5rem;
    }
    .home-right {
        display: flex;
        height: 100%;
        flex: 1;
    }
    .home-div {
        padding: 20px 10px;
    }
    .home-center {
        padding: 20px 10px 0px 10px;
    }
    .home-center-body .carousel-lecture .card-gradient-content 
    .carousel-container .carousel-content {
        height: 100vh;
        max-height: 100%;
        min-height: 100%;
    }

}

@media (min-width: 968px) { 
    .home-content {
        gap: 5px;
    }
    .home-left {
        display: flex;
        height: 100%;
        flex: 1;
    }
    .home-left .cardUser-container .card-gradient-content {
        background-image: 
            linear-gradient(to right, rgba(94, 86, 124) 0%, rgba(88 77 63 / 13%) 60%), 
            linear-gradient(to right, rgb(94, 86, 124) 0%, rgb(88 77 63 / 13%) 60%);
    } 
    .home-left .cardUser-container .card-gradient-content::after {
        border: 2px solid #584d3f00;
        border-left: none;
    }
    .home-left .cardUser-container .card-gradient-content::before {
        border: 2px solid #5E567C;
        border-right: none;
    }
    .home-left .cardUser-container .card-gradient-content .cardUser .cardUser-column,
    .home-left .cardSection-gradient .card-gradient-content .cardSection {
        justify-content: flex-start;
        text-align: start;
        }
    
}

@media (min-width: 1200px) { 
    .home-right {
        max-width: 465px;
    }
    .home-content {
        gap: 30px;
    }
    .home-center-body .carouselHoroscopeMin .card-gradient-content 
    .carouselHoroscopeMin-container .carouselHoroscopeMin-list 
    .itemHoroscopeMin-container {
        max-width: 71.988px;
        min-width: 55.05px !important;
    }
    .home-center-body .carouselHoroscopeMin .card-gradient-content 
    .carouselHoroscopeMin-container .carouselHoroscopeMin-list {
        gap: 15px
    }
    .home-center-body .carouselHoroscopeMin .card-gradient-content 
    .carouselHoroscopeMin-container .carouselHoroscopeMin-list 
    .itemHoroscopeMin-container .itemHoroscopeMin-name {
        font-family: 'Poppins-Regular';
        font-size: 0.75rem;
    }
    .home-center-body .carouselHoroscopeMin .card-gradient-content 
    .carouselHoroscopeMin-container .carouselHoroscopeMin-list 
    .itemHoroscopeMin-container .horoscopeSection--icon {
        width: 24px;
        height: 24px;
    }
    .home-center-body .carouselHoroscopeMin .card-gradient-content 
    .carouselHoroscopeMin-container .carouselHoroscopeMin-list 
    .itemHoroscopeMin-active .microtext-header {
        font-size: 0.75rem;
        font-family: 'FuturaPT-Bold';
    }
}