.cover {
    background: transparent;
    margin-top: 2rem;
    height: 100%
}
.cover-container {
   /* height: 600px; */
   height: 27rem;
   width: 99%;
   margin-left: 0.1rem;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   background-image: none;
   /* background-image: url("/public/images/png/Zinos_Landing_Page_Desktop_03.png"); */
   background-position: center; /* Center the image */
   /*background-size: contain;  Resize the background image to cover the entire container */
   background-size: cover;
   background-repeat: no-repeat;
}
.cover-animation {
   position: absolute;
   z-index: 2;
}
.cover-title {
   max-width: 370px;
   text-align: center;
   padding: 38px 25px 0px 25px;

   font-family: 'FuturaPT-Bold';
   font-size: 1.625rem;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
   z-index: 2;
   color: var(--white);
}
.cover-buttons { 
   display: flex;
   flex-direction: column;
   gap: 20px;
   position: absolute;
   bottom: -7rem;
   z-index: 2;
}
.cover-buttons .card-gradient .card-gradient-content {
    width: 100%;
    max-width: 337px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-image: 
        linear-gradient(to right, rgb(255 197 2) 56%, rgb(242 107 18) 80%), 
        linear-gradient(to right, rgb(255 197 2) 70%, rgb(242 107 18) 96%);
    background-size: 100% 2px;
}
.cover-buttons .card-gradient .card-gradient-content::before {
    width: 19px;
    border: 2px solid #ffc502;
    left: 0;
    border-right: none;
}
.cover-buttons .card-gradient .card-gradient-content::after {
    width: 18px;
    border: 2px solid #f26b12;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}

.cover-text-white {
    color: var(--white);
}

.cover-buttons .cover-buttons-gradient-1 .button .button-text-resalt { 
    font-family: 'FuturaPT-Bold';
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text span {
    color: var(--white);
    font-family: 'FuturaPT-Bold';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cover-img {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cover-img img {
    display: flex;
    padding-top: 30rem;
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text {
    height: 54px;
    background-size: 100% 1px;
    backdrop-filter: blur(10px);
    background-image:
        linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 0%) 96%), 
        linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 3%) 90%)
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text::before {
    width: 34px;
    border: 1px solid #ebebed;
    border-right: none;
    border-radius: 24px 0 0 24px;
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text::after {
    width: 34px;
    border: 1px solid #fdfdfd03;
    border-left: none;
    border-radius: 0 24px 24px 0;
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text span {
    font-size: 0.875rem;
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.018) 0%, rgba(255, 255, 255, 0.14) 100%);
    background-image: 
        linear-gradient(to right, rgb(255 255 255 / 3%) 0%, rgba(255, 255, 255, 0.104) 96%), 
        linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgba(255, 255, 255, 0.38) 90%)
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text:hover::before {
    width: 34px;
    border: 1px solid #fdfdfd03;
    border-right: none;
}

.cover-buttons .card-gradient .card-gradient-content .button--gradient-text:hover::after {
    width: 34px;
    border: 0px solid #ebebed;
    border-left: none;
}

@media (min-width: 600px) { 
    .cover-title {
        max-width: 480px;
        font-size: 2.5rem;
    }
    .cover-buttons .cover-buttons-gradient-1 .button { 
        font-size: 1.3rem;
    }
}

@media (min-width: 769px) { 
    .cover {
        display: flex;
        flex-direction: row;
        background-image: none;
        margin-top: 6rem;
        justify-content: center;
        gap: 1.5rem;
        padding: 0px 20px;
        height: 100% !important;
    }
    
    .cover-container {
        height: 34rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-image: none;
    }
    
    .cover-animation {
        display: flex;
        position: relative;
        width: 320px;
        height: 405px;
    }

    .cover-title {
        width: 100%;
        max-width: 100%;
        font-size: 2.125rem;
        padding: 0px;
        color: var(--white);
        text-align: center;
    }
    .cover-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;
        align-items: center;
        gap: 10px;
    }
    .cover-buttons .card-gradient .card-gradient-content {
        max-width: 607px;
        gap: 20px;
    } 
    .cover-buttons .card-gradient .card-gradient-content .button--gradient-text span {
        font-size: 1.125rem;
    }
    .cover-buttons .small-bold {
        font-size: 1.563rem;
    }
    .cover-text-decription {
        font-size: 1rem;
        margin-top: -10px;
    }
    .cover-img img {
        padding-top: 26rem;
    }
}

@media (min-width: 870px) { 
    .cover-title {
        font-size: 2.3rem;
    }
}

@media (min-width: 1024px) { 
    .cover-title {
        font-size: 2.7rem;
    }
}

@media (min-width: 1200px) {
    .cover-title {
        font-size: 3.125rem;
    }
    .cover-buttons {
        gap: 30px;
    }
}