.login {
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    padding: 20px;

    overflow: auto;
    position: fixed;
    z-index: 9000;
    /* background-image: url('../../../public/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TA.png'); */
    background-color: #000000;
    background-attachment: initial;
    background-position: top;
}
 
.login-body {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 23px;
    width: 100%;
    padding: 20px;
}

.login .card-background {
    width: 100%;
    max-width: 370px;

    display: flex;
    min-height: fit-content;
    height: fit-content;

    background: none;
    backdrop-filter: none;
    border: 0px;
    border-radius: 0px;
    padding: 0px;
}

.login-container {
    width: 100%;

    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
}

.login-container--header {
    width: 100%;

    display: flex;
    /* padding: 20px 0px; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.login-container--close {
    display: none;
    cursor: pointer;
}

.login-container--close div {
    display: flex;
    cursor: pointer;
}

.login-text {
    display: none;
    color: var(--white);
    font-family: 'Poppins-Medium';
    font-size: 1rem;
}

.login-body {
    width: 100%;

    display: flex;
    justify-content: center;
}

.login-loader {
    width: 500px;
    display: flex;
    justify-content: center;
}


@media (min-width: 768px) { 
    .login { 
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: rgb(0 0 0 / 75%);
        background-image: none
    }

    .login .card-background{
        width: 100%;
        max-width: 450px;
        min-height: fit-content;
        height: fit-content;
    
        display: flex;
        flex-direction: column;
        padding: 20px;
        align-items: flex-start;
        gap: 30px;

        border-radius: 24px;
        border: 1px solid #F5F5F7;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgb(255 255 255 / 28%) 100%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    .login-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }

    .login,
    .login-container {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .login-container--close {
        display: flex;
    }

    .login-body {
        background: transparent;
        backdrop-filter: none;
        box-shadow: 5px 5px 4px 0px rgb(255 255 255 / 0%) inset, -5px -5px 4px 0px rgb(255 255 255 / 0%) inset;
    }

    .login-body .loginSocial {
        max-width: 450px;
    }


    .login-text {
        display: flex;
        font-family: 'FuturaPT-Bold';
        font-size: 1.625rem;
    }

    .login-loader {
        height: 500px;
        width: 500px;
        display: flex;
        justify-content: center;
    }
}