.button-container {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;

    cursor: pointer;
}


.btn-transparent-blur {
    color: #FFFFFF;

    border: 1px solid rgba(84, 63, 212, 0.30);
    /* border-image: linear-gradient(135deg, rgba(4, 63, 212, 0.30) 0%, rgb(255 117 16 / 30%) 100%) 1; */
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
}

.btn-radius-12 {
    border-radius: 12px;
}

.btn-radius-20 {
    border-radius: 20px;
}

.button-whiteText {
    color: #FFFFFF;
}

.btn-red {
    background-color: var(--red);
    color: #FFFFFF;
}

.btn-yellow {
    background-color: var(--yellow);
    color: #FFFFFF;
}

.btn-orange {
    background-color: var(--orange);
    color: #FFFFFF;
}

.btn-white {
    background-color: var(--white);
    color: #000000;
}


.btn-radius-50 {
    border-radius: 50px;
}