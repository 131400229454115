.registerPay {
    width: 100%;
    
    display: flex;
    flex-direction: column;

    gap:20px
}

.registerPay .creditCard #creditCard-culqi {
    height: 66vh;
}

@media (min-width: 430px) {
    .registerPay .creditCard #creditCard-culqi {
        height: 64vh;
    }
        
}