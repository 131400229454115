.mercadoPagoCheckout-container {
    width: 100%;   
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Mercado pago section */
/* change title style  */

#cardPaymentBrick_container {
    font-family: "Poppins-Medium" !important; 
}
.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-z99e1s
.container-1-e9Cv.svelte-1oil008 h1{
    font-family: "Poppins-Medium";

}  

.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-z99e1s
.form-inputs-XXOP_G > div  {
    font-family: "Poppins-Regular";
}

.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-z99e1s .form-inputs-XXOP_G 
div.input-container-wsQRdj.svelte-1rrxwpg div.svelte-yix5kt {
    font-family: "Poppins-Regular" !important;
}

.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-m1hcx2 .svelte-m1hcx2 {
    background-color: rgba(0, 0, 0, 0.20);
}

.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-m1hcx2 .svelte-m1hcx2 
input.split-1zOvfQ.autocomplete-13HSli.svelte-1f0hohh {
    background-color: rgba(0, 0, 0, 0);
}

.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-z99e1s .fade-wrapper-3i8Jlc 
.row-2G1Bzh.margin-top-17dgjZ.svelte-9ubrt1 {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
}

.mercadoPagoCheckout-container #cardPaymentBrick_container .svelte-z99e1s .fade-wrapper-3i8Jlc 
.row-2G1Bzh.margin-top-17dgjZ.svelte-9ubrt1 .button-container-3sQnyo.svelte-9ubrt1 button.svelte-15hy5j4 {
    padding: 10px 20px !important;
    width: 150px;
}