@font-face {
    font-family: "FuturaPT-Bold";
    src: url('Fonts/FuturaPT-Bold.ttf') format('truetype'),
    url('Fonts/FuturaPT-Bold.otf') format("opentype");
}

@font-face {
    font-family: "FuturaPT-Medium";
    src: url('Fonts/FuturaPT-Medium.ttf') format('truetype'),
    url('Fonts/FuturaPT-Medium.otf') format("opentype");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url('Fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('Fonts/Poppins-SemiBold.otf') format("opentype");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url('Fonts/Poppins-Medium.ttf') format('truetype'),
    url('Fonts/Poppins-Medium.otf') format("opentype");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url('Fonts/Poppins-Regular.ttf') format('truetype'),
    url('Fonts/Poppins-Regular.otf') format("opentype");
}

*{
    --background: url(https://storage.santazinos.com/public/assets/images/png/background-card.png);

    box-sizing: border-box;

    --black: #1D1D1F;
    --black-80: rgba(29, 29, 31, 0.80);
    --black-60: rgba(29, 29, 31, 0.60);
    --black-40: rgba(29, 29, 31, 0.40);
    --black-20: rgba(29, 29, 31, 0.20);

    --gray-1: #444;
    --gray-2: #666;
    --gray-3: #999;
    --gray-4: #B1B1B1;
    --gray-5: #DADADA;

    --white: #F5F5F5;
    --white-80: rgba(245, 245, 247, 0.80);
    --white-60: rgba(245, 245, 247, 0.60);
    --white-40: rgba(245, 245, 247, 0.40);
    --white-20: rgba(245, 245, 247, 0.20);

    --orange: #FF9525;
    --yellow: #FFAC0B;
    --creme-1: #BF9467;
    --creme-2: #D5B491;
    --creme-3: #FFE4C7;

    --blue-dark-1: #212532;
    --blue-dark-2: #393E4F;
    --blue-dark-3: #5F83F9;
    --blue-dark-4: #A7B3DB;

    --red: #FF4848;
}   

.app {
    height: "-webkit-fill-available";
    min-height: 100vh;
    width: 100%;
    /* background: url('../public/images/png/Zinos_Landing_Page_Mobile_Fondo_2@3x.png'); */   
}

.admin-bg {
	background-image: url(https://storage.googleapis.com/storage.santazinos.com/public/assets/images/png/fondo-dashboard.png);
	background-size: cover;
	min-height: 100vh;
    width: 100%;
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.mega-header {
	font-family: 'Futura-Bold';
    font-size: 3.125rem;
	font-style: normal;
	font-weight: 350;
	line-height: normal;
	color: var(--black);
}

.big-header {
    font-family: 'FuturaPT-Bold';
    font-size: 2.125rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	color: var(--black);
}

.big-subheader {
    font-family: 'FuturaPT-Medium';
    font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	color: var(--black);
}

.medium-header {
	font-family: 'FuturaPT-Bold';
    font-size: 1.625rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	color: var(--black);
}

.small-header {
    font-family: 'FuturaPT-Bold';
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--black);
}

.small-subheader {
    font-family: 'FuturaPT-Medium';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    color: var(--black);
}

.small-bold {
    font-family: 'FuturaPT-Bold';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--black);
}

.paragraph-header {
    font-family: 'Poppins-Medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--black);
}

.paragraph-subheader {
    font-family: 'Poppins-SemiBold';
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--black);
}

.paragraph {
    font-family: 'Poppins-Regular';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--black);
}

.smalltext-header {
    font-family: 'Futura-Bold';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
    color: var(--black);
}

.smalltext {
    font-family: 'Poppins-Regular';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400; 
    line-height: normal;
    color: var(--black);
}

.tinytext-header-2 {
    font-family: 'FuturaPT-Bold';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--black);
}

.tinytext-header-1 {
    font-family: 'Poppins-Medium';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--black);
}

.tinytext-header {
    font-family: 'Poppins-SemiBold';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--black);
}

.tinytext {
    font-family: 'Poppins-Regular';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--black);
}

.microtext-header {
    font-family: 'FuturaPT-Bold';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--black);
}

.microtext {
    font-family: 'Poppins-Regular';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--black);
}

.tinytext-error {
    font-family: 'Poppins-Regular';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--red);
}