.reffledProducts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.reffledProducts .headerSection .headerSection-text-title {
    width: 70%;
    text-align: center;
}

.reffledProducts .headerSection .headerSection-text-subTitle {
    width: 70%;
    text-align: center;
}

.reffledProducts-scroll {
    width: 100%;
    min-height: 346px;
    min-width: none;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 4px 0px 12px 4px;
    
    scrollbar-gutter: stable;
    background: transparent;
}

.reffledProducts-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: flex-start;
    width: 100%;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    /* padding-left: 2rem;
    padding-right: 2rem; */
    /* max-width: 1065px; */
    padding-left: 20px;
    padding-right: 20px;
}

.reffledProducts-row::-webkit-scrollbar {
    display: none;
} 

@media (min-width: 370px) {  
    .reffledProducts .headerSection .headerSection-text-title {
        width: 65%;
    }
}

@media (min-width: 390px) {  
    .reffledProducts .headerSection .headerSection-text-subTitle {
        width: 56%;
    }
}

@media (min-width: 449px) {  
    .reffledProducts .headerSection .headerSection-text-title {
        width: 100%;
    }
}

@media (min-width: 600px) {  
    .reffledProducts .headerSection .headerSection-text-subTitle {
        width: 70%;
    }
}

@media (min-width: 900px) { 
    .reffledProducts-row {
        justify-content: center;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: 1000px) {  
    .reffledProducts .headerSection .headerSection-text-title {
        width: 100%;
    }
    .reffledProducts .headerSection .headerSection-text-subTitle {
        width: 100%;
    }
    .reffledProducts-row {
        width: 100%;
    }
}

@media (min-width: 1103px) {
    .reffledProducts-scroll {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 1150px) {
    .reffledProducts-scroll::-webkit-scrollbar {
        background-color: transparent;
        height: 0px;
        display: none;
    }

    .reffledProducts-scroll::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .reffledProducts-scroll:hover::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}