.detailInformation-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.detailInformation-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.detailInformation-textColor {
    color: white;
}

/* .detailInformation-line {
    width: 100%;
    height: 0.5px;
    background-color: white;
} */

.detailInformation-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.card-background .detailInformation-button .button-container {
    border-radius: 24px;
}

.detailInformation-button .btnIcon-extra-small {
    padding: 10px 20px;
    border-radius: 50px;
}

.detailInformation-button .btnIcon-extra-small .custom-button-icon-text {
    color: #FFFFFF;
    font-family: 'Futura-Bold';
    font-size: 0.875rem;
}

.detailInformation-content .detailInformation-row .labelText-container {
    max-width: 100%
}

.detailInformation-editButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
}

.detailInformation {
    border-bottom: 1px solid white;
    padding-bottom: 1rem;
    border-top: 1px solid white;
    padding-top: 1rem;
}

@media (min-width: 600px) { 
    .detailInformation-content .detailInformation-row {
        flex-wrap: wrap;
        width: 100%;
        gap: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .detailInformation-content .detailInformation-row .textField-container,
    .detailInformation-content .detailInformation-row .phoneCountry,
    .detailInformation-content .detailInformation-row .countryList {
        width: 49%;
    }
    .detailInformation-content .textField-container {
        width: 100%;
    }
    .detailInformation-content .detailInformation-row .labelText-container {
        max-width: 49%
    }
    .detailInformation-row .dropdown {
        width: 49%;
    }
}

@media (min-width: 768px) {
    .detailInformation-content .detailInformation-row .labelText-container {
        max-width: 100%;
    }
    .detailInformation-content .detailInformation-row .textField-container, 
    .detailInformation-content .detailInformation-row .phoneCountry, 
    .detailInformation-content .detailInformation-row .countryList {
        width: 100%;
    }
    .detailInformation-row .dropdown {
        width: 100%;
    }
 }

@media (min-width: 1000px) {  
    .detailInformation-content .detailInformation-row {
        gap: 1rem;
    }

    .detailInformation-content .detailInformation-row-four {
        display: flex;
        flex-direction: column;
    }

    .detailInformation-content .detailInformation-row-four .detailInformation-row {
        width: 100%;
    }

    .detailInformation-row .dropdown {
        width: 47%;
    }

    .detailInformation-content .detailInformation-row .textField-container,
    .detailInformation-content .detailInformation-row .phoneCountry,
    .detailInformation-content .detailInformation-row .countryList {
        width: 47%;
    }

    .detailInformation-content .detailInformation-row .labelText-container {
        width: 47%;
    }
}

@media (min-width: 1200px) {  
    .detailInformation-content .detailInformation-row-four {
        display: flex;
        flex-direction: row;
    }

    .detailInformation-content .detailInformation-row-four .detailInformation-row {
        width: 48.5%;
    }

    .detailInformation-row .dropdown {
        width: 48%;
    }
}