.menu {
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    gap: 20px;
}

.menu:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(10px);
    border-radius: 12px;
}

.menu-active {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 10px 15px;
    gap: 20px;
}

.menu-img-content {
    width: 20px;

    display: flex;
    justify-content: center;
}

.menu-img {
    display: flex;
    height: 16px;
}

.menu-text {
    color: var(--white);
}