.store {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.store-gradient {
    border-radius: 24px;
}

.store-gradient .card-gradient-content {
    border-radius: 24px;
    border: 2px solid rgba(255, 255, 255, 0.20);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0) 100%);
    background-image: none;
    padding: 20px;
}

.store-gradient .card-gradient-content::before, 
.store-gradient .card-gradient-content::after {
    border: 0px solid #5f46bc;
    border-radius: 20px 0 0 20px;
}
.store-gradient .card-gradient-content::after {
    border-radius: 0 20px 20px 0;
}

.store-row-gradient {
    border-radius: 20px;
}

.store-gradient .card-gradient-content .store  
.store-row-gradient .card-gradient-content {
    border: none;
    border-radius: 20px;
    background-image: 
        linear-gradient(to right, rgb(224 225 227) 18%, rgb(255 255 255 / 3%) 60%), 
        linear-gradient(to right, rgb(244 244 244) 0%, rgb(255 255 255 / 3%) 60%) !important;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    background-position: 0 100%, 0 0;
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
    &:before,
    &:after {
        width: 41px;
        border: 2px solid #ebebed;
        border-right: none;
        border-radius: 20px 0 0 20px;
    }
    
    &:after {
        width: 41px;
        border: 2px solid #7e85924f;
        border-left: none;
        border-radius: 0 20px 20px 0;
    }
}

.store-gradient .card-gradient-content .store 
.store-row-gradient .card-gradient-content::after,
.store-gradient .card-gradient-content .store 
.store-row-gradient .card-gradient-content::before {
    border: 2px solid #ebebed;
    border-right: none;
    border-radius: 20px 0 0 20px;
    width: 41px;
}

.store-gradient .card-gradient-content .store 
.store-row-gradient .card-gradient-content::after {
    border: 2px solid #7e859200;
    border-left: none;
    border-radius: 0 20px 20px 0;
    width: 0px;
}

.store-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    border-radius: 12px;
}

.store-row .tinytext {
    font-size: 0.65rem;
}

.store-text {
    color: var(--white);
}

.store-row .store-text_ {
    font-size: 0.75rem;
     font-family: 'Poppins-Medium'
}

.store-img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
}

@media (min-width: 1024px) { 
    .store-gradient .card-gradient-content .store 
    .store-row-gradient .card-gradient-content {
        gap: 10px;
    }
    .store-row .tinytext {
        font-size: 0.75rem;
    }
    
    .store-row  .store-text_ {
        font-size: 0.9rem;
    }
}


@media (min-width: 1240px) { 
    .store-gradient .card-gradient-content .store 
    .store-row-gradient .card-gradient-content {
        gap: 14px;
    }
}