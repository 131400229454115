.myReceipts-container{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 30px;

    /* padding: 6rem 1.5rem 3rem 1.5rem; */
}

.myReceipts-text {
    display: flex;
    color: #FFFFFF;
}

.myReceipts-container div .receipt-mobile {
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.myReceipts-container .receipts-content {
    display: none;
}
.myReceipts-container .myReceipts-content {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

@media (min-width: 1400px) {   
    .myReceipts-text,
    /* .myReceipts-container div .receipt-mobile {
        display: none;
    } */
    .myReceipts-container .myReceipts-content {
        display: none;
    }
    .myReceipts-container .receipts-content {
        display: flex;
    }
}