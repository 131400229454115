.loginSocial {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 30px;
}

.loginSocial-text-color {
    color: var(--white);
}

.loginSocial-text {
    text-align: center;
}

.loginSocial-buttons {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.loginSocial-buttons .custom-button-icon {
    height: 45px;
}

.loginSocial-text-orange {
    color: var(--orange);
    cursor: pointer;
}

.loginSocial-footer {
    display: flex;
    flex-direction: row;
    gap: 5px;

    justify-content: center;
}