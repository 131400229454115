.cardUser-container {
    gap: 25px !important;
    border-radius: 18px!important;
}

.cardUser {
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    /* padding-bottom: 1rem; */
}

.cardUser-column {
    display: flex;
    flex-direction: column;
}

.cardUser-text {
    color: var(--white);
}

.cardUser-hr {
    height: 1px;
    width: 100%;
    background-color: var(--white);
    border: none;
    margin-top: 1rem;
    margin-bottom: 0rem;
    /* margin: 0; */
}

.cardUser-menu {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 1.5rem;
}

.cardUser-menu-body {
    width: 100%;
    max-width: 100%;
    
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media (min-width: 1000px) { 
    .cardUser {
        gap: 0.5rem;
    }
}

@media (min-width: 1260px) { 
    .cardUser {
        gap: 1rem;
    }
}