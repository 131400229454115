.footer {
    /* background-image: url(/public/images/png/fondo2.png); */
    background-position: center;
    background-size: cover;

    margin-top: -0.5rem;
}

hr { 
    height: 1px;
    width: 90%;
    background-color: var(--black);
    border: none;
}

.footer-content {
    display: flex;
    gap: 30px;
    flex-direction: column;
    max-width: 1500px;
    justify-content: space-around;
    align-items: self-start;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 30px 20px;
    flex-shrink: 0;
    
    /* display: grid; */
    /* grid-auto-rows: auto; */
    /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
    /* grid-column-gap: 1em;
    grid-row-gap: 2rem; */
}

.footer-first {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-social {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.footer-second,
.footer-third,
.footer-four {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-text {
    color: var(--black);
}

.footer-link {
    cursor: pointer;
}

.footer-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.footer-row div img {
    width: 15px;
    height: 15px;
    display: flex
}

.footer-row div {
    display: flex;
    align-items: center;
}

.footer-instagram {
    cursor: pointer;
}

/*IsLogin*/
.footer-login .footer-content_ hr {
background-color: var(--white);
}

.footer-login .footer-content_ .footer-content .footer-first .footer-text,
.footer-login .footer-content_ .footer-content .footer-second .footer-text,
.footer-login .footer-content_ .footer-content .footer-third .footer-text, 
.footer-login .footer-content_ .footer-content .footer-four .footer-text {
    color: var(--white);
}

.footer-instagram,
.footer-facebook,
.footer-tiktok {
    cursor: pointer;
}

.footer-tablet {
    display: flex;
    flex-direction: column;
    gap: 30px
}

@media (min-width: 600px) {
    hr {
        width: 94%;
    }
}

/* @media (min-width: 600px) {
    .footer-content {
        display: grid; 
        grid-auto-rows: auto;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-column-gap: 1em;
        grid-row-gap: 2rem;
    }
} */

@media (min-width: 650px) {
    .footer-tablet {
        display: flex; 
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        justify-content: space-between;
    }
}

@media (min-width: 768px) {
    hr {
        background-color: var(--white);
    }
    .footer {
        display: flex;
        justify-content: center;
        /* background: #010408; */
        background-image: none;
    }
    .footer-login {
        display: flex;
        justify-content: center;
    }
    .footer-content_ {
        width: 100%;
        /* max-width: 80%; */

        display: flex;
        flex-direction: column;
    }
    .footer-content { 
        display: flex;
    }
    .footer-text {
        color: var(--white);
    }
    
}

@media (min-width: 968px) {
    .footer-content_ { 
        max-width: 100%;
    }
    .footer-content {
        display: grid;  
        grid-auto-rows: auto;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
    .footer-tablet {
        min-width: 603px;
    }
    hr {
        width: 96%;
    }
}

@media (min-width: 1010px) {
    .footer-first {
        min-width: 250px;
    }
    .footer-content {
        gap: 30px;
        justify-content: center;
        align-items: center;
        align-self: center;
        display: flex;
        flex-direction: row;
    }
    .footer-tablet {
        min-width: 700px;
    }
}

@media (min-width: 1204px) {
    .footer-content_ {
        /* max-width: 86%; */
        justify-content: center;
        align-items: center;
    }
    .footer-content {
        width: 100%;
        max-width: 72rem;
    
        
    }
}

@media (min-width: 1440px) { 
    .footer-content {
        max-width: 85rem;
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
    }
}

@media (min-width: 1447px) { 
    .footer-content {
        max-width: 85rem;
        grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
    }
}