.changePlan-row {
    display: flex;
    flex-direction: row;
    gap: 1rem
}
.changePlan-row .goBack-back {
    width: fit-content;
}
.changePlan-row .goBack-back .goBack-text {
    display: none;
}
.changePlan-text {
    text-align: center;
    cursor: pointer;
}
.changePlan-text-white {
    color: var(--white);
}
.changePlan-plan {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.changePlan-gradient {
    min-width: 100%;
    max-width: 100%;
    backdrop-filter: blur(0px);
    background: linear-gradient(110deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 108.88%);
}
.changePlan-gradient .card-gradient-content {
    min-width: 100%;
    max-width: 100%;
    flex-direction: column !important;
    gap: 10px!important;
    padding: 20px !important;
    align-items: flex-start;
    background-image: 
        linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 13%) 60%), 
        linear-gradient(to right, rgb(244 244 244) 0%, rgb(255 255 255 / 13%) 60%) !important;
    background-size: 100% 2px;
}
.changePlan-gradient .card-gradient-content::before {
    border: 1px solid #ebebed !important;
    border-right: none !important;
}
.changePlan-gradient .card-gradient-content::after {
    border: 1px solid #7b7b7b3d !important;
    border-left: none !important;
}
.changePlan-active .card-gradient-content {
    background-image: linear-gradient(to right, rgb(255 149 38) 0%, rgb(255 149 38) 60%), linear-gradient(to right, rgb(255 149 38) 0%, rgb(255 149 38) 60%) !important;
    background-size: 100% 2px !important;
}
.changePlan-active .card-gradient-content::before {
    border: 2px solid var(--orange, #FF9525) !important;
    border-right: none;
}
.changePlan-active .card-gradient-content::after {
    width: 20px;
    border: 2px solid var(--orange, #FF9525) !important;
    border-left: none;
}
.changePlan-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.changePlan-title {
    width: 100%;
    text-align: start;
}
.changePlan-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.changePlan-button {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
}

.changePlan-button .button-container {
    width: 241px;
}

.changePlan-header_ {
    /* width: 100%;
    max-width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px
}

.changePlan-header_ .changePlan-popular .card-gradient-content {
    background-size: 100% 2px;
    background-image: 
        linear-gradient(to right, rgb(255 199 0 / 100%), rgb(241 104 18) 100%), 
        linear-gradient(109deg, rgb(255 199 0 / 100%), rgb(241 104 18) 100%) !important;
}

.changePlan-header_ .changePlan-popular .card-gradient-content::before,
.changePlan-header_ .changePlan-popular .card-gradient-content::after {
    width: 20px;
    border: 2px solid #fcb404 !important;
    border-right: none !important;
}

.changePlan-header_ .changePlan-popular .card-gradient-content::after {
    width: 20px;
    border: 2px solid #f47b0e !important;
    border-left: none !important;
}

.changePlan-popular {
    max-width: fit-content !important;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
    background: linear-gradient(135deg, rgb(255 255 255 / 0%) 0%, rgba(255, 255, 255, 0.00) 0%);
}
.changePlan-popular .card-gradient-content {
    min-width: 50px !important;
    padding: 5px 30px !important;
    height: 29px !important;
    
    flex-direction: row;
    justify-content: center;
    max-width: fit-content !important;
}

.changePlan-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.changePlan-text-black {
    color: #393E4F;
}

@media (min-width: 1024px) {  
    .changePlan-header_ {
        width: 100%;
        max-width: 160px;  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .changePlan-gradient .card-gradient-content {
        flex-direction: row !important;
        align-items: center;
    }
    .changePlan-title {
        width: 100%;
        max-width: 110px;
        text-align: center;
        font-size: 1rem;
    }
    .changePlan-column {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1200px) { 
    .changePlan-gradient .card-gradient-content {
        gap: 20px!important;
    }
}