.headerSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.headerSection-text {
    color: var(--white);
}

@media (max-width: 1000px) {  
    .headerSection-text-title {
        font-family: 'FuturaPT-Bold';
        font-size: 2.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .headerSection-text-subTitle {
        font-family: 'Poppins-Regular';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


@media (max-width: 600px) {  
    .headerSection {
        gap: 5px
    }
    .headerSection-text-title {
        font-family: 'FuturaPT-Bold';
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .headerSection-text-subTitle {
        font-family: 'Poppins-Regular';
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}