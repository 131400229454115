.phoneCountry {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 2px;
    align-items: flex-start;
    gap: 5px;
}

.phoneCountry-container {
    position: relative;
   width: 100%;
   padding: 14px 16px;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   gap: 10px;

   border-radius: 16px;
    background: rgba(0, 0, 0, 0.20);

}

.phoneCountry-country {
    /* width: 40%; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.phoneCountry-textWhie {
    color: white;
    font-family: 'Poppins-Medium';
}

.phoneCountry-id {
    cursor: pointer;
    height: 100%;
}

.phoneCountry-text {
    color: #FFF;
    cursor: pointer;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.48px;
    opacity: 0.6;
}

.phoneCountry-number {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
}

.phoneCountry-country--flag {
    height: 16px;
}

.phoneCountry--list {
    width: 100%;
    max-height: 15rem;
    height: fit-content;
    position: absolute;
    top: 3.3rem;
    left: 0px;
    z-index: 5;

    padding: 6px 2px;

    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: var(--gray-5);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
}

.phoneCountry--list .countryList-container .textField-container .textField-input {
    color: var(--black);
}

.phoneCountry--list .countryList .countryList-container .textField-container {
    gap: 0px;
}

.phoneCountry-country--flag {
    display: flex;
    width: 30px;
}
.phoneCountry-container .phoneCountry-number {
    outline: 1.5px solid transparent;
}
.phoneCountry-container:focus {
    outline: 1.5px solid #A7B3DB;
}

.phoneCountry-container:active {
    outline: 1.5px solid #A7B3DB;
}

.phoneCountry-container:checked {
    outline: 1.5px solid #A7B3DB;
}