.whatsappSection {
    padding: 30px 20px;
    background: #E7EDE8;
}
.whatsappSection-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}
.whatsappSection-first {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.whatsappSection-first .emailSection-container .emailSection .card-gradient-content
.emailSection-content .phoneCountry .phoneCountry-container .phoneCountry-number {
    color: var(--black);
}

.whatsappSection-first-header {
    max-width: 338px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    text-align: center;
}
.whatsappSection-text {
    color: var(--blue-drak-2);
}
.whatsappSection-second-img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    min-width: 300px;
}
@media (min-width: 500px) {  
    .whatsappSection-first-header .medium-header {
        font-size: 1.725rem;
    }
    .whatsappSection-first-header .tinytext-header {
        font-size: 0.875rem;
    }
}
@media (min-width: 600px) {  
    .whatsappSection {
        padding: 40px 30px;
    }
}
@media (min-width: 700px) {  
    .whatsappSection-first-header {
        max-width: 390px;
    }
    .whatsappSection-first-header .medium-header {
        font-size: 1.925rem;
    }
    .whatsappSection-first-header .tinytext-header {
        font-size: 1rem;
    }
    .whatsappSection-second-img {
        max-width: 600px;
        min-width: 400px;
    }
}
@media (min-width: 869px) {  
    .whatsappSection-content {
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }
    .whatsappSection-first {
        height: 320px;
    }
    .whatsappSection {
        padding: 0px 30px 40px 30px;
    }
}
@media (min-width: 976px) {  
    .whatsappSection {
        display: flex;
        justify-content: center;
    }
    .whatsappSection-first {
        max-width: 500px;
        width: 100%;
        height: 380px;
    }
    .whatsappSection-first-header {
        max-width: 485px; 
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .whatsappSection-second-img {
        max-width: 600px;
        min-width: 433px;
    }
    .whatsappSection-first-header .medium-header {
        font-size: 2.125rem;
        width: 400px;
    }
    .whatsappSection-first-header .tinytext-header {
        font-size: 1.25rem;
        font-family: "FuturaPT-Medium";
    }
}
@media (min-width: 1200px) {
    .whatsappSection-content {
        gap: 0px
    }
    .whatsappSection-first {
        max-width: 600px;
    }
    .whatsappSection-first-header {
        max-width: 600px;
    }
    .whatsappSection-first-header .medium-header {
        width: 600px;
        font-size: 3.125rem;
    }
}