.subscriptionConcept {
    width: 100%;

    display: flex;
    flex-direction: column;
    border-radius: 24px;
    overflow: hidden;
}

.subscriptionConcept-header {
    height: 45px;
    background: var(--white);
    padding: 15px 20px;

    border-radius: 24px 0px 0px 0px;
}

.subscriptionConcept-body {
    border: 0px solid transparent;
    border-radius: 0px;

    gap: 7px;
}

ul {
    padding: 0px 16px 0px 16px;
}

.subscriptionConcept-text {
    color: var(--white);
}

.subscriptionConcept-footer {
    height: 45px;

    display: flex;
    flex-direction: row;
    gap: 5px;
    background: var(--orange);
    /* background-image: url(../../../public/images/png/selectBackground.png); */

    padding: 0px 24px 0px 16px;

    align-items: center;
    justify-content: end;
}
