.itemHoroscopeMin-container {
    max-width: 71.988px;
    min-width: 55.05px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    scroll-snap-align: center;
    cursor: pointer;
}

.itemHoroscopeMin-name {
    color: white;
}

.itemHoroscopeMin-active {
    width: 100%;
    padding: 10px;
    border-radius: 12px;
    border: 2px solid #FFF;

    background: #FFF;

    backdrop-filter: blur(45px);
}

.itemHoroscopeMin-container:hover {
    width: 100%;
    padding: 10px;
    border-radius: 12px;
    backdrop-filter: blur(45px);
    background-image: 
        linear-gradient(to right, rgb(255 255 255 / 3%) 0%, rgba(255, 255, 255, 0.104) 96%), 
        linear-gradient(to right, rgb(255 255 255 / 0%) 0%, rgba(255, 255, 255, 0.258) 90%)
}

.microtext-header {
    font-size: 0.75rem;
}

@media (min-width: 1200px) { 
    .horoscopeSection--icon,
    .itemHoroscopeMin-active .horoscopeSection--icon {
        height: 20px;
        width: 20px;
    }
    .itemHoroscopeMin-active  { 
        min-width: fit-content;
    }

    .itemHoroscopeMin-container .tinytext, 
    .itemHoroscopeMin-container .microtext-header {
        font-family: 'Poppins-Medium';
        font-size: 0.9rem;
    }

    .itemHoroscopeMin-container {
        min-width: fit-content;
    }

    .itemHoroscopeMin-name {
        font-size: 15.692px;
    }
}