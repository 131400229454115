.landingPage {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    
}
.landingPage-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.landingPage-First {
    width: 100%;
    max-width: 1440px;
    min-height: 100vh;
    /* background-image: url('../../../public/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TAMA_SIN-ZINOS.png'); */
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-attachment: initial;
    background-position: top;
    background-color: #000000;
    background-repeat: no-repeat;
    padding-bottom: 100px;
}

.landingPage-Second {
    background-color: #e7ede8;
}

.landingPage-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: 50px;
}

@media (min-width: 551px) {  
    .landingPage-First {
        width: 100%;
        max-width: 1440px;
        min-height: 100vh;
        /* background-image: url('../../../public/images/png/ZINOS_IMAGEN_FONDO_DESKTOP_MENOR-TAMA_SIN-ZINOS.png'); */
        /* background-size: contain; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #000000;
        background-attachment: initial;
        background-position: top;
    }
}

@media (min-width: 768px) { 
    .landingPage-container {
        gap: 80px
    }
}



@media (min-width: 1000px) { 
    .landingPage-container {
        gap: 100px
    }
}