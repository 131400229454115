.planSection {
    width: 100%;
    /* height: 77rem; */
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    position: relative;
    justify-content: end;
    /* padding: 7.4rem 0rem; */
}

.planSection-animation {
    width: 100%;
    max-width: 250px;
    display: flex;
    align-self: self-start;
}

.planSection-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
}

.planSection .headerSection .headerSection-text-title {
    width: 70%;
    text-align: center;
}

.planSection .headerSection .headerSection-text-subTitle {
    width: 80%;
    text-align: center;
}

.planSection-scroll {
    width: 100%;
    /* min-height: 1093px; */
    overflow-x: auto;
    overflow-y: hidden;
    padding: 4px 0px 0px 4px;
   
    scrollbar-gutter: stable;
    background: transparent;
}

.planSection-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    width: 100%;

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding-left: 2rem;
    padding-right: 2rem;
}

.planSection-row::-webkit-scrollbar {
    display: none;
} 


.body-effect {
    background: linear-gradient(-45deg, var(--yellow), #e8ca00, var(--yellow), #ffa719);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    /* height: 40vw; */
    height: 10vw;
    clip-path: polygon(0 86%, 100% 0%, 100% 100%, 0% 100%);
    width: 100%;
    position: absolute;
}


@media (min-width: 370px) {  
    .planSection .headerSection .headerSection-text-title {
        width: 65%;
    }
}

@media (min-width: 390px) {  
    .planSection .headerSection .headerSection-text-subTitle {
        width: 77%;
    }
}

@media (min-width: 600px) {  
    .planSection .headerSection .headerSection-text-title {
        width: 55%;
    }
}

@media (min-width: 1000px) {  
    .planSection .headerSection .headerSection-text-title {
        width: 50%;
    }
    .carousel-content:hover::-webkit-scrollbar {
        display: none;
        height: 4px;
        width: 3px;
      }
      .carousel-content::-webkit-scrollbar-thumb {
        background-color: var(--blue-dark-4);
        height: 5px;
        border-radius: 10px;
      }

    .planSection-row {
        width: 100%;
        justify-content: center;
    }
}


@media (min-width: 1270px) { 
    .planSection-scroll {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        min-height: fit-content;
    }
    .planSection-scroll::-webkit-scrollbar {
        background-color: transparent;
        height: 0px;
        display: none;
    }

    .planSection-scroll::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .planSection-scroll:hover::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}
