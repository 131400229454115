.tableBody-container {
    /* padding: 10px; */
}

.tableBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    justify-content: space-between;
}

.tableBody-text {
    color: #FFF;
    width: 102.86px;
    font-family: 'Poppins-Medium';
    font-size: 0.75rem;
}

.tableBody-img {
    width: 102.86px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}