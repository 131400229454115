.carouselCard-container {
    min-width: 285px;
    width: 100%;
    /* height: 201px; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
    
    scroll-snap-align: center;
}

.carouselCard-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

 .carouselCard-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
 }

 .carouselCard-title div img{
    width: 24px;

 }
 
 .carouselCard-text {
    color: var(--white);
 }

 .carouselCard-content {
    width: 100%;
    height: 100%;
 }

 .carouselCard-showMore {
    color: #B7E9FF
 }

 .carouselCard-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.carouselCard-footer div img {
    width: 73px;
    display: flex;
}

.carouselCard-footer--likes {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.carouselCard-footer--likes div img {
    width: 20px;
    display: flex;
}

.carouselCard-video {

    width: 100%;
    max-height: 451px;
    height: 100%;
}

@media (min-width: 600px) { 
    .carouselCard-container {
        width: 245px;
    }
}