.creditCard .card-background {
    width: 100%;

    display: flex;
    gap: 30px;
    border: 1px solid rgba(84, 63, 212, 0.30);
}

.creditCard-row {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 10px;
}

.creditCard-body {
    width: 100%;
    height: 42vh;
    border-radius: 24px;
}

.creditCard .card-background .button-container {
    background: var(--orange);
    color: var(--white);

    padding: 10px 20px;
    border-radius: 50px;
}

.creditCard-text-orange {
    color: var(--orange);
}

.creditCard-text {
    color: var(--white);
}

.creditCard-footer {
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.creditCard-row .textField-container {
    width: 31%;
}

.creditCard .card-background {
    padding: 0px;
}