.productAward {
    width: 100%;

    border: 2px solid rgba(84, 63, 212, 0.30);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
    border-radius: 24px;

    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
    justify-content: space-between;
}

.productAward-left {
    width: 100%;
    max-width: 350px;

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px
}

.productAward-img-row {
    width: 212px;
    height: 212px;
    border-radius: 12px;
    object-fit: cover;
}

.productAward-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.productAward-text {
    color: var(--white);
}

.productAward-text-red {
    color: var(--red);
}

.productAward-right {
    display: flex;
    width: 100%;
    max-width: 300px;

    flex-direction: column;
    text-align: start;
    justify-content: center;
}

@media (min-width: 440px) { 
    .productAward-left { 
        flex-direction: row;
    }
    .productAward-img-row {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 700px) {  
    .productAward-left {
        flex-direction: row;
    }
    .productAward {
        flex-direction: row;
    }
    
    .productAward-right {
        text-align: end;
    }
}
@media (min-width: 700px) {  
    .productAward-left {
        max-width: 300px;
    }
}