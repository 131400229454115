.textArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding: 0px 2px;
}

.textArea-container {
    display: flex;
    border-radius: 16px;
    background: rgb(0 0 0 / 42%);
    border: 0px;
    color: #FFF;
    width: 100%;
}

.textArea-textColor {
    color: white;
    font-family: "Poppins-Medium";
}