.DetailSubscription {
    display: flex;
    flex-direction: column;

    gap: 30px
}

.detailSubscription-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.detailSubscription-separator {
    width: 100%;
    background: #FFF;
}

.detailSubscription-body {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
}

.detailSubscription-text-white {
    color: var(--white);
}

.detailSubscription-text-yellow {
    color: var(--yellow);
}

.detailSubscription-body .labelText-container {
    width: 100%;
}

.detailSubscription-creditNumber {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.detailSubscription-creditNumber .detailSubscription-text-yellow { 
    text-align: end;
}

@media (min-width: 500px) { 
    .detailSubscription-creditNumber {
        display: flex;
        flex-direction: row;
        justify-content: space-between;        
        align-items: center;
        gap: 0px
    }
}

@media (min-width: 600px) { 
    .detailSubscription-body .labelText-container {
        width: 16rem;
    }
}

@media (min-width: 730px) { 
    .detailSubscription-body .labelText-container {
        width: 20rem;
    }
    .detailSubscription-body .labelText-container {
        width: 10rem
    }
}

@media (min-width: 830px) { 
    .detailSubscription-body .labelText-container {
        width: 13rem
    }
    .detailSubscription-body {
        gap: 16px;
    }
}

@media (min-width: 900px) { 
    .detailSubscription-body {
        gap: 20px;
    }
}

@media (min-width: 1069px) { 
    .detailSubscription-body {
        gap: 13px;
    }
    .detailSubscription-body .labelText-container {
        width: 9rem
    }
}

@media (min-width: 1101px) { 
    .detailSubscription-body {
        gap: 11px;
    }
    .detailSubscription-body .labelText-container {
        width: 9rem
    }
}

@media (min-width: 1200px) { 
    .detailSubscription-body {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .detailSubscription-body .labelText-container {
        width: 9rem
    }
}

@media (min-width: 1250px) { 
    .detailSubscription-body .labelText-container {
        width: 10rem
    }
}

@media (min-width: 1300px) { 
    .detailSubscription-body .labelText-container {
        width: 11rem
    }
}