  .dropdown {
    width: 100%;

    display: flex;
    padding: 0px 2px;
    flex-direction: column;
    gap: 0.3rem;
  }

  .dropdown-content {
    min-width: 150px;
    max-width: 100%;
    width: 100%;
    position: relative;
    
    border-radius: 16px;
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    /* background-image: url(../../../public/images/png/selectBackground.png); */
  }

  .dropdown-content-active {
    border-radius: 16px 16px 0px 0px;
  }

  .dropdown-text {
    color: var(--white);
    font-family: "Poppins-Medium";
  }
  
  .dropdown-header {
    padding: 14px 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-header-active {
    border-bottom: 1px solid var(--white-60);
  }
  
  .dropdown-body {
    display: none;
  }
  
  .dropdown-body.open {
    width: 100%;
    height: 150px;
    display: block;

    position: absolute;
    background: #5a5a5a;
    border-radius: 0px 0px 24px 24px;
    z-index: 1;
    overflow: auto;
  }

  .dropdown-body.open::-webkit-scrollbar {
    display: none;
  }
  
  .dropdown-item {
    padding: 10px;
  }
  
  .dropdown-item:hover {
    cursor: pointer;
    background-color: var(--gray-3);
    border-radius: 12px;
  }
  
  .dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
  }
  
  .dropdown-item-dot.selected {
    opacity: 1;
  }
  
  .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
  }
  
  .icon.open {
    transform: rotate(90deg);
  }