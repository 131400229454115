
.card-gradient {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);

    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
    border-radius: 23px;
    width: 100%;
}

.card-gradient-content {
    width: 100%;

    cursor: pointer;
    font-size: 17px;
    font-weight : normal;
    color: #fff;
    outline : none;
    border: none;
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 22px;
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(84, 63, 212) 0%, rgba(255 117 16 / 30%) 60%), linear-gradient(to right, rgb(84, 63, 212) 0%, rgb(255 117 16 / 30%) 60%);
    background-size: 100% 2px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box;
    color: #fff;
  
    &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 21px;
            border: 2px solid #5f46bc;
            border-right: none;
            border-radius: 30px 0 0 30px;
        }
        
        &:after {
            border: 2px solid #68391c;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 30px 30px 0;
        }
}

.card-gradient-content::before,
.card-gradient-content::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 21px;
    border: 2px solid #5f46bc;
    border-right: none;
    border-radius: 30px 0 0 30px;
}

.card-gradient-content::after {
    border: 2px solid #68391c;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}