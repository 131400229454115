.contactInformation-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
}

.contactInformation-container .card-gradient {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.08) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.04) inset;
}

.contactInformation-container .card-gradient .card-gradient-content {
    padding: 30px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    background-size: 100% 1px;
    background-image: 
        linear-gradient(to right, rgb(84 63 212 / 50%) 0%, rgba(255 117 16 / 14%) 100%), 
        linear-gradient(to right, rgb(84 63 212 / 50%) 0%, rgb(255 117 16 / 8%) 100%);
}

.contactInformation-container .card-gradient .card-gradient-content::before {
    border-right: none !important;
    border: 1px solid #5b47c366;
}

.contactInformation-container .card-gradient .card-gradient-content::after {
    width: 19px;
    border-left: none !important;
    border: 1px solid #76543e2b;
}

.contactInformation-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contactInformation-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.contactInformation-textColor {
    color: white;
}

.contactInformation-line {
    width: 100%;
    height: 0.5px;
    background-color: white;
}

.contactInformation-button {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.card-background .contactInformation-button .button-container {
    border-radius: 24px;
}