.admin-container  {
    margin: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}
.content-outlet {
    flex-grow: 1;
    /*Fondo*/
    display: flex;
    align-items: center;
    background: #000000;
    flex-direction: column;
}