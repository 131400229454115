.product-gradient {
    max-width: 425px;
    height: fit-content;
    width: 100%;

    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);

    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    /* backdrop-filter: blur(10px); */
    border-radius: 24px;
    width: fit-content;
}

.product-gradient:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.083) 0%, rgba(255, 255, 255, 0.312) 100%);;
    animation: ani 0.7s steps(70) infinite;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.product-gradient-row {
    width: 100%;
    min-width: 305px;
}

.product-gradient-row .product {
    border: none;
    position: relative;
    z-index: 2;
    background-color: transparent;
    background-image: 
        linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
        linear-gradient(to right, rgb(255, 199, 0) 26%, rgb(241 104 18 / 100%) 100%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    /* backdrop-filter: blur(10px); */
    border-radius: 24px;
    background-size: 100% 2px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box;
}

.product-gradient-row .product::before, 
.product-gradient-row .product::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 21px;
    border: 2px solid #FFC700;
    border-right: none;
    border-radius: 30px 0 0 30px;
}

.product-gradient-row .product::after {
    border: 2px solid #F16812;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}

.product-gradient-column .product {
    border: none;
    position: relative;
    z-index: 2;
    background-color: transparent;
    background-image: 
        linear-gradient(to right, rgba(255 183 13) 13%, rgba(238 91 16) 60%), 
        linear-gradient(to right, rgb(255 183 13) 70%, rgb(238 91 16) 91%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
    border-radius: 24px;
    background-size: 100% 2px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box;
}

.product-gradient-column .product::before,
.product-gradient-column .product::after  {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 23px;
    border: 2px solid #FFC502;
    border-right: none;
    border-radius: 30px 0 0 30px;
}

.product-gradient-column .product::after {
    border: 2px solid #F16812;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}

.product-background {
    padding: 20px;
    width: 200px;
    height: 346px;
}

.product-background-style {
    max-width: 425px;
    min-width: 305px;
    width: 100%;
    height: 100%;
    padding: 10px 15px;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.product-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    justify-content: space-between;
    height: 100%;
}

.product-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.product-img-column {
    width: 156px;
    height: 140px;
    border-radius: 10px;
    background: var(--white);
    object-fit: cover;
}

.product-img-row {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    display: block;
    background: var(--white);
    object-fit: contain;
}

.product-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3.5px;
}

.product-status {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    /* font-size: 1.125rem; */
}

.product-text {
    color: var(--white);
}

/* .product-text-title {
    font-weight: bold;
} */

.product-footer-win {
    display: flex;
    flex-direction: column;
}

.product-win-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product-footer-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/*Amtes**/