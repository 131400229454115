/* .cardSection-gradient .card-gradient-content {
    background-image: linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 30%) 60%), linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 30%) 60%);
    &:before,
    &:after {
        border: 1px solid #f0f0f0;
        border-right: none;
        border-radius: 31px 0 0 31px;
    }
    
    &:after {
        border: 1px solid #717376;
        border-left: none;
        border-radius: 0 31px 31px 0;
    }
} */

.cardSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cardSection-gradient {
    border-radius: 24px;
}

.cardSection-gradient .card-gradient-content {
    border-radius: 24px;
    border: 2px solid rgba(255, 255, 255, 0.20);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0) 100%);
    background-image: none;
    padding: 20px;
}

.cardSection-gradient .card-gradient-content::before, 
.cardSection-gradient .card-gradient-content::after {
    border: 0px solid #5f46bc;
    border-radius: 24px 0 0 24px;
}
.cardSection-gradient .card-gradient-content::after {
    border-radius: 0 24px 24px 0;
}

.cardSection-row-gradient {
    border-radius: 12px;
}

.cardSection-gradient .card-gradient-content .cardSection 
.cardSection-row-gradient .card-gradient-content {
    padding: 0px;
    border: none;
    border-radius: 17px;
    background-image: 
        linear-gradient(to right, rgb(99 75 201) 0%, rgb(129 91 66) 60%), 
        linear-gradient(to right, rgb(99 75 201) 0%, rgb(130 91 67) 60%) !important;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    background-position: 0 100%, 0 0;
    &:before,
    &:after {
        border: 1px solid #634bc9;
        border-right: none;
        border-radius: 11px 0 0 11px;
    }
    
    &:after {
        border: 1px solid #915a3abf;
        border-left: none;
        border-radius: 0 11px 11px 0;
    }
}

.cardSection-gradient .card-gradient-content .cardSection 
.cardSection-row-gradient .card-gradient-content::after,
.cardSection-gradient .card-gradient-content .cardSection 
.cardSection-row-gradient .card-gradient-content::before {
    border: 1px solid #7055be;
    border-right: none;
    border-radius: 11px 0 0 11px;
}

.cardSection-gradient .card-gradient-content .cardSection 
.cardSection-row-gradient .card-gradient-content::after {
    border: 1px solid #825b43;
    border-left: none;
    border-radius: 0 11px 11px 0;
}

.cardSection-row {
    display: flex;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 12px;
}

.cardSection-text {
    color: var(--white);
}

.cardSection-text_ {
    font-size: 0.813rem;
}