.giveaways-gradient {
    width: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    height: 100%;
}
.giveaways {
    width: 100%;
    height: 100%;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    /* border-radius: 24px; */
    /* border: 1px solid rgba(84, 63, 212, 0.30); */
    /* background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%); */
    /* box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset; */
    /* -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); */
}

.giveaways-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.giveaways-date {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.giveaways-date-gradient {
    width: fit-content; 

    /* background-image: url(../../../public/images/png/selectBackground.png); */
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    -webkit-backdrop-filter: blur(45px);
    backdrop-filter: blur(45px);
    background-size: cover;
    border-radius: 23px;
}

.giveaways-date-gradient-active {
    background-color: var(--white);
}

.giveaways-date-gradient-active .giveaways-date-content-text {
    color: var(--black);
}

.giveaways-gradient .card-gradient-content {
    height: 100%;
    background-size: 100% 2px;
    padding: 20px;
    background-image: 
        linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
        linear-gradient(to right, rgb(255, 199, 0) 26%, rgb(241 104 18 / 100%) 100%)
}

.giveaways-gradient .card-gradient-content::before,
.giveaways-gradient .card-gradient-content::after {
    border: 2px solid #FFC700;
    border-right: none;
}

.giveaways-gradient .card-gradient-content::after {
    border: 2px solid #F16812;
    border-left: none;
}

.giveaways-date .giveaways-date-content {
    max-width: 60px;
    max-height: 56px;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;

    cursor: pointer;
    font-size: 17px;
    font-weight : normal;
    color: #fff;
    outline : none;
    border: none;
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 22px;
    background-color: transparent;
    background-image: 
        linear-gradient(to right, rgb(255 255 255) 32%, rgba(255, 255, 255, 0.127) 60%), 
        linear-gradient(to right, rgb(244 244 244) 34%, rgba(255, 255, 255, 0.043) 60%);
    background-size: 100% 2px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box;
    color: #fff;
}

.giveaways-date .giveaways-date-content::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 21px;
    border: 2px solid #ffffff;
    border-right: none;
    border-radius: 30px 0 0 30px;
}

.giveaways-date .giveaways-date-content::after {
    border: 2px solid #939597a1;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}

.giveaways-date-gradient-active .giveaways-date-content {
    background-image: 
        linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255) 60%), 
        linear-gradient(to right, rgb(244 244 244) 0%, rgb(255 255 255) 60%);
}

.giveaways-date-gradient-active .giveaways-date-content::before {
    border: 2px solid #ffffff !important;
    border-right: none !important;
}

.giveaways-date-gradient-active .giveaways-date-content::after {
    border: 2px solid #ffffff !important;
    border-left: none !important;
}

.giveaways-date-content-text {
    color: var(--white);
}

.giveaways-products {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    overflow-x: none;
    /* scroll-snap-type: x mandatory; */
}

.giveaways-products::-webkit-scrollbar {
    display: none;
}

.giveaways-products .product-gradient-row {
    min-width: 100px;
}

.giveaways-products .product-gradient-row .product {
    min-width: 100px;
    &:after {  
        border-left: none;
    }
}

.text_ {
    display: flex;
    margin-top: -0.4rem;
    margin-bottom: -0.4rem;
}

.giveaways-products .product-gradient-row .product .product-row .product-detail {
    text-align: left;
}

.giveaways-products .product-gradient-row .product .product-row .product-status,
.giveaways-products .product-gradient-row .product .product-status {
    text-align: right;
    font-size: 0.75rem;
}

.giveaways-products .product-gradient-row .product .product-row .product-detail 
    .product-text-title {
        font-size: 0.75rem;
}


@media (min-width: 360px) { 
    .giveaways-date-gradient {
        padding: 0px;
        border-radius: 8px;
    }
    .giveaways-date .giveaways-date-content {
        padding: 10px 18px;
    }
    .giveaways-date-content .giveaways-date-content-text {
        font-size: 0.8rem;
    }
    .giveaways-date .giveaways-date-content {
        border-radius: 10px;
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 6px;
            border: 2px solid #ffffff;
            border-right: none;
            border-radius: 9px 0 0 9px;
        }
        
        &:after {
            border: 2px solid #939597a1;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 9px 9px 0;
        }
    }
}

@media (min-width: 441px) { 
    .giveaways-products {
        width: 100%;
        align-items: center;
    }
}

@media (min-width: 500px) { 
    .giveaways-gradient {
        max-width: 465px;
        margin: auto;
    }
}

@media (min-width: 600px) { 
    .giveaways-date-gradient {
        padding: 0px;
        border-radius: 15px;
    }
    .giveaways-date-content .giveaways-date-content-text {
        font-size: 0.8rem;
    }
    .giveaways-date .giveaways-date-content {
        /* padding: 10px 8px; */
        border-radius: 17px;
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 14px;
            border: 2px solid #ffffff;
            border-right: none;
            border-radius: 25px 0 0 25px;
        }
        
        &:after {
            border: 2px solid #93959700;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 25px 25px 0;
        }
    }
}

@media (min-width: 690px) { 
    .giveaways-date .giveaways-date-content {
        padding: 10px 13px;
    }
}

@media (min-width: 968px) { 
    .giveaways-date .giveaways-date-content { 
        padding: 10px 11px;
    }
}

/* @media (min-width:968px) {
    .giveaways-products .product-gradient-row .product .product-row .product-status {
        font-size: 1.125rem;;
    }
} */

@media (min-width: 1000px) {
    .giveaways-gradient .card-gradient-content {
        padding: 20px;
    }
    /* .giveaways-products .product-background-style .product-row 
    .product-status {
        width: 50%;
        font-size: 0.7rem;
    } */
    .giveaways-products .product-background-style .product-row  {
        gap: 10px
    }
    /* .giveaways-products:hover::-webkit-scrollbar {
        display: flex;
        height: 4px;
        width: 4px;
    } */
    .giveaways-products::-webkit-scrollbar-thumb {
        background-color: var(--blue-dark-4);
        height: 5px;
        width: 5px;
        border-radius: 10px;
    }
    .giveaways-date-gradient {
        border-radius: 10px;
    }
    .giveaways-date .giveaways-date-content {
        padding: 10px 8px;
    }
    .giveaways-date-content .giveaways-date-content-text {
        font-size: 0.8rem;
    }

    .giveaways-date .giveaways-date-content {
        border-radius: 10px;
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 14px;
            border: 2px solid #ffffff;
            border-right: none;
            border-radius: 9px 0 0 9px;
        }
        
        &:after {
            border: 2px solid #93959712;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 9px 9px 0;
        }
    }
}

@media (min-width: 1200px) { 
    .giveaways-gradient .card-gradient-content {  
        padding: 20px;
        gap: 20px;
    }
    /* .giveaways-date {
        gap: 20px;
    } */
    .giveaways-date .card {
        padding: 15px;
    }
    .giveaways-date .giveaways-date-content div{
        font-size: 1rem;
    }
    .giveaways-date-gradient {
        border-radius: 15px;
    }
    .giveaways-date .giveaways-date-content {
        border-radius: 17px;
        padding: 10.4px 14.9px;
        height: 63px;
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            bottom: 0;
            width: 15px;
            border: 2px solid #ffffff;
            border-right: none;
            border-radius: 15px 0 0 15px;
        }
        
        &:after {
            border: 2px solid #939597a1;
            border-left: none;
            left: auto;
            right: 0;
            border-radius: 0 15px 15px 0;
        }
    }
    .giveaways-date .giveaways-date-content::before,
    .giveaways-date .giveaways-date-content::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        bottom: 0;
        width: 15px;
        border: 2px solid #ffffff;
        border-right: none;
        border-radius: 15px 0 0 15px;
    }
    .giveaways-date .giveaways-date-content::after {
        border: 2px solid #93959700;
        border-left: none;
        left: auto;
        right: 0;
        border-radius: 0 15px 15px 0;
    }
}

@media (min-width: 1280px) { 
    .giveaways-products .product-gradient-row .product .product-row .product-detail 
    .product-text-title, .giveaways-products .product-gradient-row .product .product-row .product-status,
    .giveaways-products .product-gradient-row .product .product-status {
        font-size: 0.875rem;
    }
}