.subscription {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 30px;

    /* padding: 6rem 1.5rem 3rem 1.5rem; */
}

.contactProfile-container .deletedSubscription .button-container {
    background: #FFF;
    color: #000;
}

.subscription .card-gradient .card-gradient-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-size: 100% 1px;
    background-image: 
        linear-gradient(to right, rgb(84 63 212 / 58%) 0%, rgba(255 117 16 / 19%) 74%), 
        linear-gradient(to right, rgb(84 63 212 / 58%) 0%, rgb(255 117 16 / 15%) 60%);
}

.subscription .card-gradient  .card-gradient-content::before {
    border: 1px solid #5b47c366;
    border-right: none !important;
}   

.subscription .card-gradient  .card-gradient-content::after {
    border: 1px solid #76543e30;
    border-left: none !important;
}

.subscription .card-gradient .card-gradient-content .adminPaid-card 
.card-gradient-content .creditNumber .creditNumber-input .creditNumber-data 
.creditNumber-number {
    font-size: 0.75rem;
}

.subscription .card-gradient .card-gradient-content .adminPaid-card 
.card-gradient-content .creditNumber .creditNumber-input .creditNumber-text {
    font-size: 0.65rem;
}

.subscription .card-gradient .card-gradient-content .adminPaid-card 
.card-gradient-content .adminPaid-left .button {
    width: 125px;
    height: 37px;
    padding: 10px 5px;
    font-size: 0.75rem;
}

@media (min-width: 991px) {
    .subscription .card-gradient .card-gradient-content .adminPaid-card 
    .card-gradient-content .adminPaid-left .button {
        width: 200px;
        padding: 10px 20px;
        font-size: 0.875rem;
    }
    .subscription .card-gradient .card-gradient-content .adminPaid-card 
    .card-gradient-content .creditNumber .creditNumber-input .creditNumber-data 
    .creditNumber-number {
        font-size: 1rem;
    }
    .subscription .card-gradient .card-gradient-content .adminPaid-card 
    .card-gradient-content .creditNumber .creditNumber-input .creditNumber-text {
        font-size: 0.8rem;
    }
}

/* @media (min-width: 1400px) {   
.subscription .card-gradient .card-gradient-content {

}
} */