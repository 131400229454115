.loginGmail {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 20px;
}

.loginGmail .button-container {
    background: var(--orange);
    color: var(--white);

    padding: 10px 20px;
    border-radius: 50px;
}

.loginGmail-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px
}

.loginGmail-footer-text {
    color: var(--white);
}

.loginGmail-footer-text-orange {
    color: var(--orange);
}