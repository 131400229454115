/* contenedor principal */
.tabs_text {
	color: var(--white);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 12px;
}

.tabsGradient {
	height: 29px;
	width: fit-content;
}

.tabsGradient .card-gradient-content {
	width: fit-content;
	height: 29px;
    padding: 5px 10px;
    background-size: 100% 2px;
    background-image: 
		linear-gradient(to right, rgba(255, 199, 0) 50%, rgba(241 104 18 / 100%) 80%), 
		linear-gradient(to right, rgb(255, 199, 0) 14%, rgb(241 104 18 / 100%) 77%)
}

.tabsGradient .card-gradient-content::after, 
.tabsGradient .card-gradient-content::before {
	border: 2px solid #FFC700;
    border-right: none;
    width: 20px;
}

.tabsGradient .card-gradient-content::after {
	border: 2px solid #F16812 !important;
    border-left: none !important;
}

.tabs-clicked .card-gradient-content {
	background-image: 
		linear-gradient(to right, rgb(255 255 255) 50%, rgb(255, 255, 255) 80%), 
		linear-gradient(to right, rgb(255, 255, 255) 26%, rgb(255, 255, 255) 100%)
}

.tabs-clicked .card-gradient-content::after, 
.tabs-clicked .card-gradient-content::before {
	border: 2px solid #FFFFFF;
    border-right: none;
    width: 20px;
}

.tabs-clicked .card-gradient-content::after {
	border: 2px solid #FFFFFF !important;
    border-left: none !important;
}

.tabs-clicked .card-gradient-content .tabs_text {
	color: var(--black);
}

.tabs:hover {
	color: var(--black);
}

.tabs_white {
	color: var(--white);
	cursor: pointer;
}

.tabs_white:hover {
	color: var(--white-75);
}

.tabs:hover::before {
	transform: scaleX(1);
}

.tabs-clicked {
	color: var(--black);
	position: relative;
    justify-content: center;
    align-items: center;
    background: var(--white, #F5F5F7);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px);
}

/* .tabs-clicked::before {
	content: "";
	width: 20px;
	height: 4px;
	border-radius: 2px;
	background-color: var(--black);
	position: absolute;
	left: calc(50% - 20px/2);
	bottom: 0%;
	margin-bottom: -10px;
	transition: none;
	transform: none;
} */
