.adminPaid-row {
    display: flex;
    flex-direction: row;
    gap: 1rem
}
.adminPaid-row .goBack-back {
    width: fit-content;
}
.adminPaid-row .goBack-back .goBack-text {
    display: none;
}
.adminPaid-text {
    text-align: center;
    cursor: pointer;
}
.adminPaid-text-white {
    color: var(--white);
}
.adminPaid-card .card-gradient-content {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    background-size: 100% 2px;
    padding: 20px !important;
    gap: 10px !important;
    background-image: 
        linear-gradient(to right, rgb(224 225 227) 0%, rgb(255 255 255 / 13%) 60%), 
        linear-gradient(to right, rgb(244 244 244) 0%, rgb(255 255 255 / 13%) 60%) !important
}
.adminPaid-card .card-gradient-content::before {
    border: 1px solid #ebebed !important;
    border-right: none !important;
}
.adminPaid-card .card-gradient-content::after{
    border: 1px solid #5554546e !important;
    border-left: none !important;
}
.adminPaid-card .card-gradient-content .button {
    height: 37px;
    display: flex;
    width: 177px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-size: 100% 1px;
    border-radius: 12px;
    background-image: linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255) 60%), linear-gradient(to right, rgb(244 244 244) 0%, rgb(255 255 255) 60%);
}

.adminPaid-card .card-gradient-content .button::after {
    border: 1px solid #fffcfc;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 12px 12px 0;
    width: 17px;
}
.subscription .card-gradient .card-gradient-content .adminPaid-left .button--gradient-text span {
    color: var(--white);
}
.subscription .card-gradient .card-gradient-content .adminPaid-left .button--gradient-text-active span {
    color: var(--black);
}
.button--gradient-text-active::after {
    border: 1px solid var(--white)!important;
    border-left: none !important;
}
.adminPaid-card .card-gradient-content .button::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 17px;
    border: 1px solid #ebebed;
    border-right: none;
    border-radius: 12px 0 0 12px;
}
.adminPaid-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.button--gradient-text-active {
    background: white;
    color: var(--black);
    font-weight: 500;
    transition: none;
    animation: none;
    border: 1px solid var(--white);
}

.adminPaid-cardDetail form .creditCard .card-background {
    background: none;
    border: 0px solid rgba(84, 63, 212, 0.30);
    backdrop-filter: none;
    padding: 0px;
}

.adminPaid-close img {
    width: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.adminPaid-card .card-gradient-content .adminPaid-left {
    width: 100%;
    justify-content: end;
}

.adminPaid-card .card-gradient-content .creditNumber {
    width: 100%;
    max-width: 100%;
}

.adminPaid-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 490px) { 
    .adminPaid-card .card-gradient-content {
        display: flex;
        flex-direction: row !important;
        gap: 10px !important;
    }
    .adminPaid-card .card-gradient-content .adminPaid-left {

    }
    .adminPaid-card .card-gradient-content .adminPaid-left {
        width: auto;
    }
}