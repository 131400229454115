
.modalsoporte .modal-container {
    width: 100%;
    max-width: 52.75rem;
    background: linear-gradient(135deg, rgb(255 255 255 / 43%) 0%, rgb(255 255 255 / 20%) 100%);
}

.modalsoporte-content  {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modalsoporte-row {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.modalsoporte-row .textField-container .textField-input {
    background: rgb(0 0 0 / 42%);
}

.modalsoporte-row .phoneCountry .phoneCountry-container {
    background: rgb(0 0 0 / 27%);
}

.modalsoporte-row .textField-container .textField-input::placeholder,
.modalsoporte-row .textField-container .textField-input::-moz-placeholder,
.modalsoporte-row .textField-container .textField-input:-ms-input-placeholder,
.modalsoporte-row .textField-container .textField-input::-ms-input-placeholder,
.modalsoporte-row .textField-container .textField-input::-webkit-input-placeholder { 
    color: var(--white-60);
}

.modalsoporte-row .phoneCountry .phoneCountry-container .phoneCountry-text::placeholder {
    color: var(--white-40);
}

.modalsoporte-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modalsoporte-footer .button-container {
    width: 100%;
    max-width: 250px;
}

@media (min-width: 630px) {   
    .modalsoporte-row {
        display: flex;
        flex-direction: row;
        gap: 1rem
    }
}