.textField-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding: 0px 2px;
}

.textField-textColor {
    color: white;
    font-family: "Poppins-Medium";
}

.textField-input {
    height: 46px;
    display: flex;
    padding: 14px 16px;
    align-items: flex-start;
    border: 0px;
    gap: 10px;
    align-self: stretch;

    border-radius: 16px;
    background: rgba(0, 0, 0, 0.20);

    color: #FFF;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.48px;
    opacity: 0.6;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.textField-input:focus {
    outline: 1.5px solid #A7B3DB;
}

.textField-input:active {
    outline: 1.5px solid #A7B3DB;
}

.textField-input:checked {
    outline: 1.5px solid #A7B3DB;
}


input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: none;
    filter: invert(50);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }