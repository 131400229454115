.card-background {
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    border-radius: 24px;
    border: 1px solid #F5F5F7;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(10px);
}