.ItemCountry-container {
    width: 100%;
    
    /* padding: 15px 20px; */
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: auto;   
    cursor: pointer;
}

.ItemCountry-container:hover {
    background-color: var(--black-40);
    border-radius: 16px;
    cursor: pointer;
}

.ItemVesItemCountrysels-container .smalltext-header {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 541px;
    width: 100%;
    white-space: nowrap;
}