.button {
    width: 100%;

    margin: 0;
    cursor: pointer;
    font-size: 17px;
    font-weight : normal;
    color: #fff;
    outline : none;
    border: none;
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border-radius: 30px;
    background-color: transparent;
    background-image: 
      linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 0%) 96%), 
      linear-gradient(to right, rgb(255 255 255) 0%, rgb(255 255 255 / 3%) 90%);
    background-size: 100% 2px;
    background-position: 0 100%, 0 0;
    background-repeat: no-repeat;
    background-clip: border-box;
    color: #fff;
}

.button::before,
.button::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  bottom: 0;
  width: 21px;
  border: 2px solid #ebebed;
  border-right: none;
  border-radius: 20px 0 0 20px;
}

.button::after {
  border: 2px solid #7e859208;
  border-left: none;
  left: auto;
  right: 0;
  border-radius: 0 20px 20px 0;
}

/* .button:hover {
  animation: ani 0.7s steps(70) infinite;
  transition: all .5s ease-in-out;
} */

.button-text-resalt {
  padding-left: 5px;
}