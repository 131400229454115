.tableHeader {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    border-bottom: 1px solid #FFF;
    justify-content: space-between;
    width: 100%;
}

.tableHeader-text {
    color: #FFF;
    width: 102.86px;
    font-family: 'Poppins-Medium';
}

@media (min-width: 600px) { 
    .tableHeader-text {
        color: #FFF;
        width: 54.86px;
        font-family: 'Poppins-Medium';
        font-size: 0.7rem;
    }
}

@media (min-width: 934px) { 
    .tableHeader-text {
        color: #FFF;
        width: 102.86px;
        font-family: 'Poppins-Medium';
    }
}