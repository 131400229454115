.horoscope-container {
  width: 100%;
  margin-top: 7rem;
  padding: 0px 10px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1;
}

.horoscope-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.horoscope-container .headerSection {
  width: 330px;
  text-align: center;
}

.horoscope-container .headerSection-text-subTitle {
  width: 300px;
}

.horoscope-text {
  color: var(--blue-dark-4);
  width: 20rem;
  text-align: center;
}

.carouselHoroscopeMin-list::-webkit-scrollbar {
  display: none;
}

.carousel-content::-webkit-scrollbar {
  display: none;
}

.horoscopeSection--icon {
  width: 24px;
  height: 24px;
}

.horoscope-content .carouselHoroscopeMin .card-gradient-content::after {
  border: 1px solid #73523f;
  border-left: none;
}

.horoscope-content .carouselHoroscopeMin .carouselHoroscopeMin-container .carouselHoroscopeMin-list .itemHoroscopeMin-container {
  min-width: fit-content;
}

@media (min-width: 420px) {
  .horoscope-text { 
    width: 25rem;
  }
}

@media (min-width: 479px) {
  .horoscope-text { 
    width: 30rem;
  }
}

@media (min-width: 573px) {
  .horoscope-text { 
    width: 35rem;
  }
}

@media (min-width: 600px) {
  .horoscope-container .headerSection .headerSection-text-title {
    max-width: 430px;
  }
  .horoscope-container .headerSection {
    width: 100%;
  }
  .horoscope-container .headerSection-text-subTitle {
    width: 100%;
  }
}

@media (min-width: 770px) {
  .horoscope-content{
    max-width: 868.5px;
  }

  .horoscope-container {
    max-width: 1095px;
    gap: 30px;
  }
  .horoscope-text { 
    width: 40rem;
  }
 
}

@media (min-width: 1000px) {
  .horoscope-content{
    max-width: 900px;
  }
  .horoscope-container .headerSection .headerSection-text-title {
    max-width: 100%;
  }
  .horoscope-container .headerSection {
    width: 700px;
  }
  .horoscope-text { 
    width: 54rem;
  }
  .carousel-content:hover::-webkit-scrollbar {
    display: flex;
    height: 4px;
    width: 3px;
  }
  .carousel-content::-webkit-scrollbar-thumb {
    background-color: var(--blue-dark-4);
    height: 5px;
    border-radius: 10px;
  }
}

@media (min-width: 1200px) {
    .horoscope-content{
      max-width: 1060px;
    }
    .horoscope-text { 
      width: 66rem;
    }
    
    .horoscopeSection--icon {
      /* width: 32px;
      height: 32px; */
      width: 25px;
      height: 25px;
    }
}