.navbar {
    position: absolute;
    width: 100%;
    /* margin-left: 0.1rem; */
    padding: 15px 40px;
    /* border: 1px solid #FFF;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.30) 100%);  */

    /* box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    backdrop-filter: blur(10px); */
    z-index: 3;
    backdrop-filter: blur(10px);
    box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
}

.navbar-showMenu {
    backdrop-filter: none;
    box-shadow: 0px
}

.navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 20px;
}

.navbar-elements {
    display: flex;
    flex-direction: row;
}

.menu-icon {
    display: none;
}

.logo {
    cursor: pointer;
}

.logo div img {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 138.086px;
    height: 27px;
}

.navbar-right {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.navbar-text-white {
    color: var(--white);
}

.navbar-elements {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.navbar-elements-logo {
    display: none;
    cursor: pointer;
}

.navbar-elements-items {
    color: var(--white);
    cursor: pointer;
}

.navbar-elements-contents {
    display: flex;
    flex-direction: column;
    gap: 30px; 
    width: 100%;
    justify-content: center;
    align-items: center;
}

.navbar-list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 40px;
    align-items: center;
}

.navbar-list-item .navbar-elements-items {
    display: none;
}

.navbar-elements-icon-close {
    display: none;
}

@media (max-width: 768px) { 
    .navbar {
        flex-shrink: 0;
        height: fit-content;
        padding: 5px 10px;
    }

    .navbar-container {
        height: 40px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .logo div img {
        width: 106px;
        height: 26.748px;
    }

    .navbar-list-item .navbar-elements-items {
        display: flex;
    }
    
    .navbar-elements {
        position: absolute;
        left: -1px;
        top: 0rem;
        width: 100%;
        height: 0px;
        flex-direction: column;
        /* height: calc(100vh - 60px); */
        transition: all 0.2s ease-out;
        overflow: hidden;
        z-index: 1;
    }

    .navbar-elements-logo {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--white);
    }

    .navbar-elements-logo div img {
        display: flex;
        width: 120px;
        height: 23.622px;
    }

    .navbar-list-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        align-items: center;
    }
    
    .navbar-elements.active {
        z-index: 1;
        background: rgba(49, 48, 54, 0.30);
        backdrop-filter: blur(20px);    
        height: 549px;
        padding: 30px 20px;
        justify-content: space-between;
    }

    .navbar-elements-icon-close {
        display: flex;
        cursor: pointer;
    }
}

@media (max-width: 600px) { 
    .navbar-container {
        height: 35px;
    }

    .logo div img {
        width: 80px;
        height: 15.748px;
    }

    .navbar-elements-items {
        font-size: 0.75rem;
    }
}