.emailSection {
    background: #ffffff;
    justify-content: center;
    display: flex;
}
 .emailSection-container {
    position: relative;
    width: 100%;
    max-width: 340px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    z-index: 1;
 }

 .emailSection-content .phoneCountry .phoneCountry-textWhie {
    display: none;
 }

 .emailSection-content .phoneCountry .phoneCountry-container {
    background: white;
    padding: 0px;
 }

 .emailSection-content .phoneCountry .phoneCountry-container:active {
    height: 38px;
 }

 .emailSection-content .phoneCountry .phoneCountry-container .phoneCountry-country .phoneCountry-text {
    color: rgba(51, 51, 51, 0.60);
 }

 .emailSection-content .phoneCountry .phoneCountry-container .phoneCountry-country .phoneCountry-number {
    color: rgba(51, 51, 51, 0.60);
 }

 .emailSection-message {
    text-align: center;
 }

.emailSection .card-gradient-content {
    width: 508px;
    height: 56px;
    align-items: center;
    display: flex;
    border-radius: 40px;
    padding: 5px 5px 5px 23px;
    background-size: 100% 3px;
    background-image: linear-gradient(90deg,#34a853,#00a4d7,#113d7f),linear-gradient(90deg,#34a853,#00a4d7,#113d7f);
}

.emailSection .card-gradient-content::before {
    width: 22px;
    border: 3px solid #2aa76d;
    border-right: none;
}

.emailSection .card-gradient-content::after {
    width: 28px;
    border: 3px solid #114f8c;
    border-left: none;
    left: auto;
    right: 0;
    border-radius: 0 30px 30px 0;
}

.emailSection-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.emailSection-input {
    width: 100%;
    color: #333333;
    border: 0;
    font-family: 'Faktum';
	font-size: 1.2rem;
}

.emailSection-input:hover, .emailSection-input:focus, 
.emailSection-input-group:hover .emailSection-input {
    outline: none;
  }

.emailSection-button {
    max-width: 113px;
    width: 100%;
    max-height: 66px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    background: #333;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    color: var(--white);
    cursor: pointer;
}
.emailSection-content .phoneCountry .phoneCountry-container 
.phoneCountry-country {
    flex-direction: row-reverse;
}
@media (min-width: 700px) {  
    .emailSection-container {
        max-width: 385px;
    }
    .emailSection-container .emailSection {
        border-radius: 34px;
    }
}
@media (min-width: 976px) {  
    .emailSection-container {
        max-width: 420px;
    }
    .emailSection .card-gradient-content {
        height: 66px;
    }
    .emailSection .card-gradient-content::before {
        width: 30px;
    }
    .emailSection-button {
        max-width: 162px;
        font-size: 1rem;
    }
    .emailSection-content .phoneCountry .phoneCountry-container 
    .phoneCountry-country .phoneCountry-text {
        font-size: 1.125rem;
    }
    .emailSection-content .phoneCountry .phoneCountry-container 
    .phoneCountry-number {
        font-size: 1.125rem;
    }
}

@media (min-width: 1200px) { 
    .emailSection-container,
    .emailSection-container .emailSection,
    .emailSection .card-gradient-content {
        max-width: 592px;
        width: 592px;
    }
}