.modalReglament-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modalReglament-title {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.modalReglament-paragraph {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}