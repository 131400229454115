.registerSocial {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.registerSocial-text {
    color: var(--white);
}

.registerSocial-buttons {
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 20px;
}

.registerSocial-buttons .custom-button-icon {
    height: 45px;
}

.registerSocial-login {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}

.registerSocial-login span {
    color: var(--blue-dark-4);
    cursor: pointer;
}