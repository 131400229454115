.modal-main {
    position: fixed;
    z-index: 8000;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0 0 0 / 72%);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.modal-container {
    max-width: 53.75rem;
    margin: auto;
    padding: 30px;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* background: rgba(255, 255, 255, 0.75); */
    /* background: var(--white); */
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(10px);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    backdrop-filter: blur(40px);
    border-radius: 24px;
}

.modal__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.modal__heade-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.modal-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-logo div {
    display: flex;
}

.modal-logo div img {
    width: 37px;
    height: 26px;
}

.modal-header__text {
    align-self: center;
    display: flex;
    flex-direction: column;
}

.modal-title,
.modal-text {
    color: var(--white);
}

@media (max-width: 600px) { 
    .modal-logo div img {
        width: 30px;
        height: 20px;  
    }
    .modal-container {
        border: 0px solid #FFFFFF;
        border-radius: 0px;
    }
}

@media (max-width: 470px) { 
    .modal-container {
        padding: 15px;
    }
}