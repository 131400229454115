.user {
    width: 51px;
    height: 51px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    border: 5px solid #FFFFFF ;
}

.user-img {
    width: 51px;
    height: 51px;
    
    display: flex;
    object-fit: cover;
}

.user-alias {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 1.25rem;
}