.labelText-container {
    max-width: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.labelText-textColor {
    width: 100%;
    color: white;
    /* font-family: "Poppins-Medium";
    font-size: 0.8rem; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}